
const eventType = {
  name: { type: 'string', required: true },
  title: { type: 'string', required: true, anonymous: true, canBeTranslated: true },
  tagLine: { type: 'string', required: false, anonymous: true, canBeTranslated: true },
  tags: { type: 'array', dataType: 'tags', anonymous: true },
  media: { type: 'array', dataType: 'media', anonymous: true },
  organisations: { type: 'array', dataType: 'organisations' },
  facilities: { type: 'array', dataType: 'facilities' },
  date: { type: 'object', dataType: 'fromToDate', hasEndDate: true, anonymous: true },
  hasRecurringDate: { type: 'bool', anonymous: true },
  recurringPattern: { type: 'recurringPattern', anonymous: true },
  publishDate: { label: 'Publiceringsdatum', type: 'object', dataType: 'fromToDate', hasEndDate: false },
  description: { type: 'rich', required: true, anonymous: true },
  webSite: { type: 'string', anonymous: true },
  facebookUrl: { type: 'string', anonymous: true },
  facebookAppUrl: { type: 'string', anonymous: true },
  instagramUrl: { type: 'string', anonymous: true },
  instagramAppUrl: { type: 'string', anonymous: true },
  links: { type: 'array', anonymous: true },
  featured: { type: 'checkbox' },
  location: { type: 'location', anonymous: true },
  address: { type: 'string', anonymous: true },
  visible: { type: 'checkbox', defaultValue: true },
  unverified: { type: 'checkbox', defaultValue: false }
}

const dataObjectDefinitions = {

  events: eventType,
  tips: {
    ...eventType,
    contactName: { type: 'string', defaultValue: false, required: true, anonymous: true },
    contactInformation: { type: 'string', defaultValue: false, required: true, anonymous: true },
  },
  organisations: {
    name: { type: 'string', required: true },
    title: { type: 'string', required: true, anonymous: true, canBeTranslated: true },
    tags: { type: 'array', dataType: 'tags' },
    media: { type: 'array', dataType: 'media' },
    description: { type: 'rich', required: true, canBeTranslated: true },
    webSite: { type: 'string' },
    facebookUrl: { type: 'string', anonymous: true },
    facebookAppUrl: { type: 'string', anonymous: true },
    instagramUrl: { type: 'string', anonymous: true },
    instagramAppUrl: { type: 'string', anonymous: true },
    links: { type: 'array', anonymous: true },
    location: { type: 'location' },
    visible: { type: 'checkbox', defaultValue: true }
  },
  facilities: {
    name: { type: 'string', required: true },
    title: { type: 'string', required: true, anonymous: true, canBeTranslated: true },
    tags: { type: 'array', dataType: 'tags' },
    media: { type: 'array', dataType: 'media' },
    description: { type: 'rich', required: true, canBeTranslated: true },
    webSite: { type: 'string' },
    facebookUrl: { type: 'string', anonymous: true },
    facebookAppUrl: { type: 'string', anonymous: true },
    instagramUrl: { type: 'string', anonymous: true },
    instagramAppUrl: { type: 'string', anonymous: true },
    links: { type: 'array', anonymous: true },
    location: { type: 'location' },
    visible: { type: 'checkbox', defaultValue: true }
  },
  push: {
    name: { type: 'string', required: true },
    title: { type: 'string', required: true },
    subTitle: { type: 'string' },
    description: { type: 'string', required: true, label: 'Detaljer', inputLabel: 'Meddelande', helpText: 'Meddelande i notisen' },
    payloadData: { type: 'string', label: 'Data', helpText: 'Data att skicka med notisen' },
    hidden: { type: 'checkbox' },
    exposureSettings: { type: 'pushExposureInfo', includeFieldsOnSave: ['tags', 'organisations', 'facilities'] }
  },
  links: {
    name: { type: 'string', required: true },
    title: { type: 'string', required: true, canBeTranslated: true },
    description: { type: 'rich', required: true, canBeTranslated: true },
    organisations: { type: 'array', dataType: 'organisations' },
    facilities: { type: 'array', dataType: 'facilities' },
    tags: { type: 'array', dataType: 'tags' },
    webSite: { type: 'string' },
    openWithoutInfoDialog: { type: 'checkbox' },
  },
  tags: {
    name: { type: 'string', required: true, canBeTranslated: true },
    title: { type: 'string', required: true, canBeTranslated: true }
  },
  admin: {
    userName: { type: 'string', required: true, readOnly: true },
    level: { type: 'string', required: true },
    familyName: { type: 'string', required: true },
    givenName: { type: 'string', required: true },
    email: { type: 'email', required: true },
    organisations: { type: 'array', dataType: 'organisations', userLevel: 'admin', required: true },
    password: { type: 'password' },
    canCopy: false
  }

}

export { dataObjectDefinitions }