import { useState, useEffect } from 'react';

import { LegacyCard, Page, ResourceItem, ResourceList, Text } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { restApi } from '../providers/restApi';
import { useHistory } from 'react-router-dom';

const FacebookPage = () => {

  const { pageId } = useParams();
  const history = useHistory();

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
    if(pageId) {
      setIsLoadingEvents(true);
      restApi.get(`/facebook/pages/${pageId}/events`)
      .then((response) => {
        setEvents(response.data);
        setIsLoadingEvents(false);
      })
    }
  }, [pageId]);

  const goToPreviousUrl = () => {
    history.goBack()
  };

  const gotoEvent = (eventId) => {
    history.push(`/facebook/${pageId}/${eventId}`)
  }

  return(
   <Page
      backAction={{content: 'Dashboard', onAction : goToPreviousUrl }}
    >
    <LegacyCard 
      title="Evenemang"
    > 
      <ResourceList
        loading={isLoadingEvents}
        items={events}
        renderItem={(item) => {
          const {id, name } = item;
          return (
            <ResourceItem
              id={id}
              accessibilityLabel={`View details for ${name}`}
              onClick={() => { gotoEvent(id) }}
            >
              <Text>{name}</Text>
            </ResourceItem>
          );
        }}
      />
    </LegacyCard>
   </Page> 
  )
}

export { FacebookPage }