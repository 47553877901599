
import { useState, useContext } from 'react';

import { Modal, Layout, FormLayout, Form, TextField } from '@shopify/polaris';

import { Auth } from 'aws-amplify';

import { AuthContext } from '../providers/AuthContext';

const Login = ({ isVisible }) => {

  const authContext = useContext(AuthContext);

  const [isLoggingIn, setIsLoggingIn] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerify, setNewPasswordVerify] = useState('');
  const [loginError, setLoginError] = useState('');
  const [cognitoUser, setCognitoUser] = useState(false);

  const [changePasswordError, setChangePasswordError] = useState('');
  const [needsToChangePassword, setNeedsToChangePassword] = useState(false);
    
  const login = async () => {
    setIsLoggingIn(true);
    setLoginError('')
    try {
      let user = await Auth.signIn(username, password);
      setIsLoggingIn(false);
      setCognitoUser(user);
      if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setEmail(user.challengeParam.userAttributes.email)
        setNeedsToChangePassword(true);
      } else {
        authContext.dispatch({ type: 'setIsAuthenticated', value: true })
      }
    } catch(e) {
      setLoginError(e.message)
      setIsLoggingIn(false);
    }
  }

  const changePassword = async () => {
    setIsChangingPassword(true);
    setChangePasswordError('')
    try {
      await Auth.completeNewPassword(cognitoUser, newPassword, { email });
      authContext.dispatch({ type : 'setIsAuthenticated', value : true })
    } catch(e) {
      console.error(e);
      setChangePasswordError(e.message)
    }
    setIsChangingPassword(false);
  }

  const loginButton = {
    content : 'Logga in', 
    onAction : login,
    loading : isLoggingIn
  }

  const changePasswordButton = {
    content : 'Byt lösenord', 
    onAction : changePassword,
    loading : isChangingPassword,
    disabled : !newPassword || !newPasswordVerify || newPassword !== newPasswordVerify
  }

  return(
    <Modal
      title={needsToChangePassword ? 'Byt lösenord' : 'Logga in'}
      open={isVisible}
      primaryAction={ needsToChangePassword ? changePasswordButton : loginButton }
    >
      <Modal.Section>
        <Form onSubmit={(needsToChangePassword ? changePassword : login)}>
          <Layout>
            <Layout.Section>
              {!needsToChangePassword && (
                <FormLayout>
                  <TextField 
                    label='Användarnamn'
                    type='text'
                    value={username}
                    onChange={setUsername}
                    error={loginError}
                  />
                  <TextField 
                    label='Lösenord'
                    type='password'
                    value={password}
                    onChange={setPassword}
                  />
                </FormLayout>
              )}
              {needsToChangePassword && (
                <FormLayout>
                  <TextField 
                    label='E-postadress'
                    type='email'
                    value={email}
                    onChange={setEmail}
                  />
                  <TextField 
                    label='Nytt lösenord'
                    type='password'
                    value={newPassword}
                    onChange={setNewPassword}
                    error={changePasswordError}
                  />
                  <TextField 
                    label='Bekräfta lösenord'
                    type='password'
                    value={newPasswordVerify}
                    onChange={setNewPasswordVerify}
                  />
                </FormLayout>
              )}
            </Layout.Section>
          </Layout>
        </Form>
      </Modal.Section>
    </Modal>    
  )

}

export { Login }