import { RadioButton, HorizontalStack } from '@shopify/polaris';
import { Spinner, Text, LegacyCard } from '@shopify/polaris';
import { useEffect, useState } from 'react';

import { restApi } from '../providers/restApi';
import { TagsSelector } from './TagsSelector';

const PushExposureInfo = ({ push, onChange, onTagsChanged, onOrganisationsChanged, onFacilitiesChanged }) => {

  const [sendInfo, setSendInfo] = useState({ MessageCount : 0 })
  const [isLoading, setIsLoading] = useState(false)
  const [data] = useState(push);

  const defaultExposureSettings = { 
    matchAllTags : push.matchAllTags,
    matchAllOrganistions : push.matchAllOrganistions, 
    matchAllFacilities : push.matchAllFacilities,
  }
  const exposureSettings = push.exposureSettings || defaultExposureSettings;

  useEffect(() => {
    (async () => {
      setIsLoading(true); 
      let sendInfo = await restApi.post(`/push/simulate-send`, { push : { ...data, tags : push.tags, organisations : push.organisations, facilities : push.facilities,  exposureSettings : push.exposureSettings } });
      setSendInfo(sendInfo);
      setIsLoading(false);
    })();
  }, [data, push.tags, push.exposureSettings, push.organisations, push.facilities]);

  const toggleMatchAllTags = (checked) => {
    if(typeof(onChange) == 'function') 
      onChange({ ...exposureSettings, matchAllTags : checked })
  }

  const toggleMatchAllOrganisations = (checked) => {
    if(typeof(onChange) == 'function') 
      onChange({ ...exposureSettings, matchAllOrganistions : checked })
  }

  const toggleMatchAllFacilities = (checked) => {
    if(typeof(onChange) == 'function') 
      onChange({ ...exposureSettings, matchAllFacilities : checked })
  }

  return(
    <LegacyCard
      title="Exponering"
    >
      <LegacyCard.Section>
        <HorizontalStack>
          <Text>
            Användare som kan nås: {!isLoading && (<strong>{sendInfo.MessageCount}</strong>)}
          </Text>
          {isLoading && (<Spinner size="small" />)}
        </HorizontalStack>
      </LegacyCard.Section>
      <LegacyCard.Section title='Taggar'>
        <TagsSelector dataType={'tags'} onChange={onTagsChanged} selectedTags={push.tags} />
      </LegacyCard.Section>
      {push.tags && push.tags.length > 0 && (
        <LegacyCard.Section>
          <RadioButton
            label="Någon tagg måste matcha"
            helpText="Notisen skickas till alla som följer minst en av de matchande taggarna"
            checked={exposureSettings.matchAllTags !== true}
            onChange={e => toggleMatchAllTags(false)}
          />
          <RadioButton
            label="Alla taggar måste matcha"
            helpText="Notisen skickas bara till de som följer alla taggarna"
            checked={exposureSettings.matchAllTags === true}
            onChange={e => toggleMatchAllTags(true)}
          />
        </LegacyCard.Section>
      )}
      <LegacyCard.Section title='Verksamheter'>
        <TagsSelector dataType={'organisations'} onChange={onOrganisationsChanged} selectedTags={push.organisations} />
      </LegacyCard.Section>
      {push.organisations && push.organisations.length > 0 && (
        <LegacyCard.Section>
          <RadioButton
            label="Någon verksamhet måste matcha"
            helpText="Notisen skickas till alla som följer minst en av de matchande verksamheterna"
            checked={exposureSettings.matchAllOrganistions !== true}
            onChange={e => toggleMatchAllOrganisations(false)}
          />
          <RadioButton
            label="Alla verksamheterna måste matcha"
            helpText="Notisen skickas bara till de som följer alla verksamheterna"
            checked={exposureSettings.matchAllOrganistions === true}
            onChange={e => toggleMatchAllOrganisations(true)}
          />
        </LegacyCard.Section>
      )}
      <LegacyCard.Section title='Anläggningar'>
        <TagsSelector dataType={'facilities'} onChange={onFacilitiesChanged} selectedTags={push.facilities} />
      </LegacyCard.Section>
      {push.facilities && push.facilities.length > 0 && (
        <LegacyCard.Section>
          <RadioButton
            label="Någon anläggning måste matcha"
            helpText="Notisen skickas till alla som följer minst en av de matchande anläggningarna"
            checked={exposureSettings.matchAllFacilities !== true}
            onChange={e => toggleMatchAllFacilities(false)}
          />
          <RadioButton
            label="Alla anläggningar måste matcha"
            helpText="Notisen skickas bara till de som följer alla anläggningarna"
            checked={exposureSettings.matchAllFacilities === true}
            onChange={e => toggleMatchAllFacilities(true)}
          />
        </LegacyCard.Section>
      )}
    </LegacyCard>     
  );

}

export { PushExposureInfo }