import { useEffect, useState, useRef, useContext } from 'react';

import { VerticalStack, RadioButton, Button, Page, ContextualSaveBar, 
  TextField, LegacyCard, Layout, FormLayout, Checkbox, Text, SkeletonPage, 
  SkeletonBodyText, PageActions, Toast, Modal, Select } from '@shopify/polaris'
import ReactQuill from 'react-quill'; // ES6

import { useParams, useHistory } from 'react-router-dom';
import { dataObjectDefinitions } from '../providers/dataObjects'

import { restApi } from '../providers/restApi';

//import { diff } from 'json-diff';

import { LocationSelector } from '../components/LocationSelector';
import { TagsSelector } from '../components/TagsSelector';
import { LinksEditor } from '../components/LinksEditor';
import { MediaUpload } from '../components/MediaUpload';
import { DateSelector } from '../components/DateSelector';
import { RecurringDateSelector } from '../components/RecurringDateSelector';
import { PushExposureInfo } from '../components/PushExposureInfo';
import { AuthContext } from '../providers/AuthContext';

const Clipboard = ReactQuill.Quill.import('modules/clipboard')
const Delta = ReactQuill.Quill.import('delta')

class PlainClipboard extends Clipboard {
  onPaste (e) {
    e.preventDefault();
    const range = this.quill.getSelection()
    const text = e.clipboardData.getData('text/plain')
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    const index = text.length + range.index
    const length = 0
    this.quill.updateContents(delta, 'silent')
    this.quill.setSelection(index, length, 'silent')
    this.quill.scrollIntoView()
  }
}

ReactQuill.Quill.register('modules/clipboard', PlainClipboard, true)

const DefaultAdminPageAction = ({ id, saveButtonDisabled, hasChanges, data }) => {
  
  const [isLoading, setIsloading] = useState(false);
  const [user, setUser] = useState(data);

  useEffect(()=> {
    setUser(data)
  }, [data]);

  const disableUser = async () => {
    setIsloading(true);
    try {
      await restApi.post(`/admin/${id}/inactivate`, {});
      setUser(user => ({ ...user, disabled : true }))
    } catch(e) {
      console.log('InactivateError', e)
    }
    setIsloading(false);
  }

  const enableUser = async () => {
    setIsloading(true);
    try {
      await restApi.post(`/admin/${id}/activate`, {});
      setUser(user => ({ ...user, disabled : undefined }))
    } catch(e) {
      console.log('ActivateError', e)
    }
    setIsloading(false);
  }

  if(!user.disabled) {
    return(
      <Button 
        loading={isLoading}
        primary
        destructive
        onClick={disableUser}
        disabled={saveButtonDisabled || hasChanges}
      >Inaktivera</Button>
    )    
  } else {
    return(
      <Button 
        loading={isLoading}
        primary
        onClick={enableUser}
        disabled={saveButtonDisabled || hasChanges}
      >Aktivera</Button>
    )
  }

}

const DefaultPushPageAction = ({ id, saveButtonDisabled, hasChanges, data }) => {
  
  const [showSendModal, setShowSendModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [selectedDates, setSelectedDates] = useState({ start : new Date(), end : new Date() });

  const [showSuccesToast, setShowSuccesToast] = useState(false);
  const [sendSuccessMessage, setSendSuccessMessage] = useState();
  const [showErrorToast, setShowErrorToast] = useState(false);
  
  const [sendErrorMessage, setSendErrorMessage] = useState('');
  const [sendInfo, setSendInfo] = useState({ MessageCount : '-' });
  
  useEffect(() => {
    (async () => {
      if(showSendModal) {
        let sendInfo = await restApi.post(`/push/simulate-send`, { push : data });
        setSendInfo(sendInfo)
      }  
    })();
  }, [data, showSendModal])

  const sendPush = async () => {
    setIsSending(true);
    try {
      await restApi.post(`/push/send/${id}`);
      setSendSuccessMessage('Notis skickad');
      setShowSendModal(false);
      setShowSuccesToast(true);
    } catch(e) {
      console.error('Error sending push', e)
      setSendErrorMessage(e.message);
      setShowErrorToast(true);
    }
    setIsSending(false);
  }

  const schedulePush = async () => {
    setIsSending(true);
    try {
      let match = selectedDates.start.toISOString().match(/([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2})/)
      const data = {
        minute : match[5],
        hour : match[4],
        date : match[3],
        month : match[2],
        year : match[1],
      }
      await restApi.put(`/push/schedule/${id}`, data);
      setSendSuccessMessage('Notis schemalagd');
      setShowSendModal(false);
      setShowSuccesToast(true);
    } catch(e) {
      console.error('Error sending push', e)
      setSendErrorMessage(e.message);
      setShowErrorToast(true);
    }
    setIsSending(false);
  }

  const cancelSendPush = () => {
    setShowSendModal(false);
  }

  const confirmPushSend = () => {
    setShowSendModal(true);
  }
  
  const handleDateChanged = (dates) => {    
    setSelectedDates(dates);
  }

  const primaryAction = isScheduled ? 
  { content : 'Schemalägg', onAction : schedulePush, loading : isSending, disabled : isSending } :
  { content : 'Skicka', onAction : sendPush, loading : isSending, disabled : isSending };

  return(
    <>
      <Button 
        onClick={confirmPushSend} 
        primary
        disabled={saveButtonDisabled || hasChanges}
      >Skicka</Button>
      <Modal
        title='Skicka pushnotis'
        onClose={e => setShowSendModal(false)}
        onDismiss={e => setShowSendModal(false)}
        open={showSendModal}
        primaryAction={primaryAction}
        secondaryActions={{ content : 'Avbryt', onAction : cancelSendPush, disabled : isSending }}
      >
        <Modal.Section>
          <VerticalStack>
            <RadioButton
              label="Skicka nu"
              helpText="Notisen kommer att skickas omdelbart"
              checked={isScheduled === false}
              onChange={e => setIsScheduled(false)}
            />
            <RadioButton
              label="Schemalägg"
              helpText="Notisen kommer att skickas vid utsatt tidpunkt"
              checked={isScheduled === true}
              onChange={e => setIsScheduled(true)}
            />
          </VerticalStack>
        </Modal.Section>
        {isScheduled && (
        <Modal.Section>
          <DateSelector 
            title={'Datum'}
            selectedDates={selectedDates}
            onChange={handleDateChanged}
          />
        </Modal.Section>
        )}
        <Modal.Section>
          <Text>Notisen kan skickas till <strong>{sendInfo.MessageCount}</strong> användare</Text>
        </Modal.Section>
      </Modal>
      {showErrorToast && (
        <Toast error onDismiss={e => setShowErrorToast(false)} content={sendErrorMessage} />
      )}
      {showSuccesToast && (
        <Toast onDismiss={e => setShowSuccesToast(false)} content={sendSuccessMessage} />
      )}
    </>
  );

}

const skeletonPage = (
  <SkeletonPage>
    <Layout>
      <Layout.Section>
        <LegacyCard >
          <LegacyCard.Section>
              <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard >
          <LegacyCard.Section>
              <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard >
          <LegacyCard.Section>
              <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard >
          <LegacyCard.Section>
              <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section secondary>
        <LegacyCard >
        <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
)

const updateDefaults = (definition, data) => {
  let newData = { ...data };
  for(var key in definition) {
    var field = definition[key];
    if(field.defaultValue) {
      if(typeof(newData[key]) === 'undefined') {
        newData[key] = field.defaultValue;
      }
    }
  }
  return(newData);
}

const CreateDataObject = (props) => {
  
  const { dataType, singularName, pluralName, pageAction, header } = props;
  const { id } = useParams();
  const history = useHistory();
  const { state : authState } = useContext(AuthContext);

  const dateSelector = useRef();

  const definition = dataObjectDefinitions[dataType];
  if(!authState.isAuthenticated) {
    for(var k in definition) {
      if(!definition[k].anonymous)
        delete definition[k];
    }
  }

  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const defaultUseStartTime = false;
  const defaultUseEndTime = false;
  defaultStartDate.setHours(0); defaultStartDate.setMinutes(0); defaultStartDate.setSeconds(0); defaultStartDate.setMilliseconds(0);
  defaultEndDate.setHours(23); defaultEndDate.setMinutes(59); defaultEndDate.setSeconds(59); defaultEndDate.setMilliseconds(0);

  const [data, setData] = useState({ });
  const [selectedDates, setSelectedDates] = useState({ start : defaultStartDate, end : defaultEndDate, useEndTime : defaultUseEndTime, useStartTime : defaultUseStartTime })
  const [selectedPublishDates, setSelectedPublishDates] = useState({ start : new Date(), end : new Date(), defaultUseEndTime, defaultUseStartTime })
  
  const [useLocation, setUseLocation] = useState();
  const [originalData, setOriginalData] = useState({ })

  // password 
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState('');  

  const [isInvalidUri, setIsInvalidUri] = useState(false);
  const [isInvalidFacebookUri, setIsInvalidFacebookUri] = useState(false);
  const [isInvalidFacebookAppUri, setIsInvalidFacebookAppUri] = useState(false);
  const [isInvalidInstagramUri, setIsInvalidInstagramUri] = useState(false);
  const [isInvalidInstagramAppUri, setIsInvalidInstagramAppUri] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);  
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  
  const [errorToastActive, setErrorToastActive] = useState(false);
  const [successToastActive, setSuccessToastActive] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [successToastMessage, setSuccessToastMessage] = useState('');
  const toggleErrorToastActive = () => { setErrorToastActive(!errorToastActive) }
  const toggleSuccessToastActive = () => { setSuccessToastActive(!successToastActive) }

  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [quillDescription, setQuillDescription] = useState('');

  /** validators */
  const [emailValidationError, setEmailValidationError] = useState(null);

  const goToPreviousUrl = () => {
    history.goBack()
  };

  useEffect(() => {
    const defaultStartDate = new Date();
    const defaultEndDate = new Date();
    defaultStartDate.setHours(0); defaultStartDate.setMinutes(0); defaultStartDate.setSeconds(0); defaultStartDate.setMilliseconds(0);
    defaultEndDate.setHours(23); defaultEndDate.setMinutes(59); defaultEndDate.setSeconds(59); defaultEndDate.setMilliseconds(0);
    if(definition && !id && definition.date) {
      const dateObject = {
        start : {
          intValue : defaultStartDate.valueOf(),
          stringValue : defaultStartDate.toISOString(),
        }, end : {
          intValue : defaultEndDate.valueOf(),
          stringValue : defaultEndDate.toISOString(),
        }
      }
      const emptyObject = updateDefaults(definition, { date : { ...dateObject }, publishDate : { ...dateObject } });      
      setData(emptyObject);
    } else {
      const emptyObject = updateDefaults(definition);      
      setData(emptyObject);
    }
  }, [definition, id]);
  
  useEffect(() => {
    if(authState.isAuthenticated) {
      const { level : userLevel } = authState.user ? authState.user : { level : 'none' };
      if(userLevel === 'admin') {
        restApi.post(`/organisations/query`, { from : 0, size : 100 }).then(r => r.Hits)
        .then(r => { return(r); })
        .then(organisations => {
          setData(data => ({ ...data, organisations : [{ id : organisations[0].id, name : organisations[0].name }] }))
        })
      }
    } 
  }, [authState]);

  const hasMissingFields = (definition, data) => {
    if(definition.date && dateSelector.current) {
      if(!dateSelector.current.hasValidDate()) return(true);
    }
    for(var key in definition) {
      var field = definition[key];
      if(field.type === 'email' && emailValidationError !== null) {
        console.log('invalid email');
        return(true);
      }
      if(field.required) {
        if(field.userLevel !== undefined && data.level !== field.userLevel)
          continue; // skip check if selected level dont match the required userLevel
        if(typeof(data[key]) === 'undefined') {
          console.log('Missing required prop', key)
          return(true);
        }
        if(field.type === 'array' && data[key].length === 0) {
          console.log('Array is empty for prop', key)
          return(true);
        }
        if(field.type === 'string' && !data[key]) {
          console.log('String is empty for prop', key)
          return(true);
        }
      }
    }
    return(false);
  }

  const languages = [
    {label: 'Svenska', value: 'sv'},
    {label: 'Engelska', value: 'en'},
  ];

  const [isLoading, setIsLoading] = useState(true);

  const verb = id ? 'Redigera' : 'Skapa';

  const contextualSaveBarMessage = 'Osparade ändringar';

  const saveButtonDisabled = isLoading || hasMissingFields(definition, data);

  const diff = (obj1, obj2) => {  
    return(true);
  }

  const isDirty = () => {
    if(isLoading) return(false);
    var hasChanges = diff(originalData, data);
    return(hasChanges);
  }

  const hasChanges = isDirty();

  useEffect(() => {
    if(dataType && id) {
      (async () => {
        setIsLoading(true);
        try {
          let dataObject = await restApi(`/${dataType}/${id}`).catch(e => { console.log(e); throw e; }); 
          const _data = updateDefaults(definition, {...dataObject, media : [...dataObject.media || []]});
          setData(_data);
          if(dataObject.date) {
            const { start, end, useEndTime, useStartTime } = dataObject.date;
            var startDate = new Date(start.intValue);          
            setSelectedDates({ start : startDate, end : end !== undefined ? new Date(end.intValue) : startDate, useEndTime, useStartTime });
          }
          if(dataObject.publishDate) {
            const { start, end, useEndTime, useStartTime } = dataObject.publishDate;
            var publishStartDate = new Date(start.intValue);
            setSelectedPublishDates({ start : publishStartDate, end : end !== undefined ? new Date(end.intValue) : publishStartDate, useEndTime, useStartTime });
          }
          setOriginalData(_data);
          setUseLocation(typeof(dataObject.location) === 'object' && dataObject.location !== null);
          setSelectedLanguage('sv');
          updateQuillDescription('sv', _data);
          setIsLoading(false);
        } catch(error) {
          console.error('Failed to get and set data', error)
          if(error.request) {
            setErrorToastMessage(`Fel ${error.request.status}`);
            setErrorToastActive(true);
          } else {
            setErrorToastMessage(`Fel ${error.message}`);
          }
        }
      })();
    } else {
      setIsLoading(false)
    }
  }, [id, dataType, definition])

  const descriptionChanged = (value) => {   
    setQuillDescription(value);
    setData(data => {
      if(selectedLanguage === 'sv') {
        return({ ...data, description: value });
      } else {      
        let translations = JSON.parse(JSON.stringify(data.translations || {}))
        if(translations[selectedLanguage]) {
          translations[selectedLanguage].description = value;
          translations[selectedLanguage].custom_description = true;
        } else {
          translations[selectedLanguage] = { description: value, customDescription: true };
        }
        return({ ...data, translations })
      }
    });
  }

  const updateQuillDescription = (selectedLanguage, data) => {
    if(selectedLanguage === 'sv') {
      setQuillDescription(data.description || '');
    } else {
      setQuillDescription((data.translations || {})[selectedLanguage]?.description || '')
    }
  }

  const handleLanguageChange = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
    setTimeout(() => {
      updateQuillDescription(selectedLanguage, data);
    }, 200);
  }

  const getTranslatedValue = (value) => {
    if(selectedLanguage !== 'sv') {
      if(data.translations && data.translations[selectedLanguage] && data.translations[selectedLanguage][value])
        return(data.translations[selectedLanguage][value]);
      else return('');
    }
    return(data[value]);
  }

  const getFilteredData = () => {
    const filteredData = { numFollowers : 0 }
    for(const key in definition) {
      if(typeof(data[key]) !== 'undefined')
        filteredData[key] = data[key];
      if(typeof(definition[key].includeFieldsOnSave) === 'object') {
        for(const includeKey of definition[key].includeFieldsOnSave) {
          if(typeof(data[includeKey]) !== 'undefined')
            filteredData[includeKey] = data[includeKey]
        }
      }
    }
    filteredData.translations = data.translations;
    return(filteredData);
  }

  const changePassword = async () => {
    setIsChangingPassword(true);
    try {
      restApi.post(`/admin/${id}/password`, { password })  
    } catch(e) {
      setErrorToastMessage(e);
      setErrorToastActive(true);
    }
    setIsChangingPassword(false);
  }

  const save = async () => {
    const filteredData = getFilteredData();    
    if(hasMissingFields(definition, filteredData)) {
      alert('Ogiltiga eller tomma fält, kan inte spara')
      return;
    }
    // dont overwrite numFollowers
    delete filteredData.numFollowers;
    setIsSaving(true);
    setTimeout(async () => {
      try {
        const saveResult = await (id ? restApi.post(`${dataType}/${id}`, filteredData) : restApi.put(`${dataType}`, filteredData));
        if(!id) {
          if(authState.isAuthenticated) {
            let checkTimer = setInterval(async () => {
              try {
                const checkedData = await restApi.get(`${dataType}/${saveResult.id}`)
                if(checkedData.id) {
                  clearInterval(checkTimer);                  
                  setSuccessToastMessage(`Sparat`);
                  setSuccessToastActive(true);
                  setIsSaving(false);          
                  history.push(`/${dataType}/${saveResult.id}`);
                }
              } catch(e) {
                console.log('checkData Failed', e);
              }
            }, (1000));
          } else {
            setTimeout(() => {
              history.push(`/tack`);
            }, (2000));
          }
        } else {
          // 
          const { data: updatedData } = saveResult;
          if(updatedData) {
            setData(data => ({ ...data, ...updatedData }));
            updateQuillDescription(selectedLanguage, updatedData)
          }
          setOriginalData(JSON.parse(JSON.stringify({ ...data, media : [...(data.media || [])] })))
          setSuccessToastMessage(`Sparat`);
          setSuccessToastActive(true);
          setIsSaving(false);  
        }
      } catch(e) {
        console.log('Failed to save', e)
        setErrorToastMessage(`${e.message || e.errorMessage || e}`);
        setErrorToastActive(true);
        setIsSaving(false);
      }
    }, 1000);
  }

  const handleDataChange = (value, field) => {
    let newData = { }    
    if(definition[field].canBeTranslated) {
      if(selectedLanguage === 'sv') {
        newData[field] = value;
      } else {
        var t = JSON.parse(JSON.stringify(data.translations || {}));
        if(t[selectedLanguage]) {
          t[selectedLanguage][field] = value;
          t[selectedLanguage][`custom_${field}`] = true;
        } else {
          t[selectedLanguage] = { [field]: value, [`custom_${field}`]: true };
        }
        newData.translations = t;
      }
    } else {
      newData[field] = value;
    }
    setData(data => ({ ...data, ...newData }))
  }

  const handleWebsiteChange = (value) => {
    handleDataChange(value, 'webSite');
  }

  const validateWebSite = () => {
    setIsInvalidUri(
      data.webSite && !data.webSite.match('^http(s?)://(.+)\\.(.+)$')
    )
  }

  const handleFacebookChange = (value) => {
    handleDataChange(value, 'facebookUrl');
  }

  const validateFacebook = () => {
    setIsInvalidFacebookUri(
      data.facebookUrl && !data.facebookUrl.match('^http(s?)://(.+)\\.(.+)$')
    )
  }

  const handleFacebookAppChange = (value) => {
    handleDataChange(value, 'facebookAppUrl');
  }

  const validateFacebookApp = () => {
    setIsInvalidFacebookAppUri(
      data.facebookAppUrl && !data.facebookAppUrl.match('^fb://(.+)$')
    )
  }

  const handleInstagramChange = (value) => {
    handleDataChange(value, 'instagramUrl');
  }

  const validateInstagram = () => {
    setIsInvalidInstagramUri(
      data.instagramUrl && !data.instagramUrl.match('^http(s?)://(.+)\\.(.+)$')
    )
  }

  const handleInstagramAppChange = (value) => {
    handleDataChange(value, 'instagramAppUrl');
  }

  const validateInstagramApp = () => {
    setIsInvalidInstagramAppUri(
      data.instagramAppUrl && !data.instagramAppUrl.match('^instagram://(.+)$')
    )
  }

  const handleTagsChanged = (tags) => {
    handleDataChange(tags, 'tags');
  }

  const handleOrganisationTagsChanged = (tags) => {
    handleDataChange(tags, 'organisations');
  }

  const handleFacilityTagsChanged = (tags) => {
    handleDataChange(tags, 'facilities');
  }

  const handleMediaChanged = (media) => {
    handleDataChange(media, 'media');
  }

  const handleLinksChanged = (links) => {
    handleDataChange(links, 'links');
  }

  /** date stuff */
  const handleDateChanged = (dates) => {
    const { start, end, useEndTime, useStartTime } = dates;
    const dateObject = { 
      start : {
        intValue : start.valueOf(),
        stringValue : start.toISOString(),
      }, end : {
        intValue : end.valueOf(),
        stringValue : end.toISOString(),
      },
      useEndTime,
      useStartTime
    };
    handleDataChange(dateObject, 'date');
  }

  /** date stuff */
  const handlePublishDateChanged = (dates) => {
    const { start, end } = dates;
    const dateObject = { 
      start : {
        intValue : start.valueOf(),
        stringValue : start.toISOString(),
      }, end : {
        intValue : end.valueOf(),
        stringValue : end.toISOString(),
      }
    };
    handleDataChange(dateObject, 'publishDate');
  }

  /** recurring stuff */
  const handleRecurringPatternChange = (enabled, pattern) => {
    handleDataChange(enabled, 'hasRecurringDate');
    handleDataChange(pattern, 'recurringPattern');
  }

  /** location change */
  const handleLocationChange = (location, address) => {
    handleDataChange(location, 'location');
    handleDataChange(address, 'address');
  }

  /** exposure changed */
  const handleExposureSettings = (exposureSettings) => {
    handleDataChange(exposureSettings, 'exposureSettings')
  }

  /** username changed */
  const handleUserLevelChange = (level) => {
    handleDataChange(level, 'level')
  }

  /** location */
  const useLocationChanged = (useLocation) => {
    setUseLocation(useLocation);
    handleDataChange(null, 'location');
    handleDataChange(null, 'address');
  }

  /** delete **/
  const confirmDeleteItem = () => {
    setDeleteModalVisible(true);
  }

  const deleteItem = () => {
    setIsDeleting(true);
    setTimeout(async () => {
      try {
        await restApi.delete(`${dataType}/${id}`);
        setTimeout(() => {
          history.push(`/${dataType}`);
        }, (2000));
        setDeleteModalVisible(false)
        setIsDeleting(false);
        setSuccessToastMessage('Borttagen');
        setSuccessToastActive(true);
      }
      catch(e) {
        setErrorToastMessage(`${e.message}`);
        setErrorToastActive(true);
        setIsDeleting(false);
      }
    }, 1000)
  }

  /** copy/deuplicate */

  const confirmCopyItem = () => {
    setCopyModalVisible(true);
  }

  const copyItem = async () => {
    setIsCopying(true);
    try {
      let filteredData = getFilteredData();
      if(filteredData.name) filteredData.name += ' - kopia';
      filteredData.visible = false;
      let putResult = await restApi.put(`${dataType}`, filteredData);
      setCopyModalVisible(false);
      setSuccessToastMessage(`Kopierat`);
      setSuccessToastActive(true);
      setIsCopying(false);
      if(putResult.status === 'ok') {
        setTimeout(() => {
          history.push(`/${dataType}/${putResult.id}`);
        }, (4000));
      }
    } catch(e) {
      setErrorToastMessage(`${e.message}`);
      setErrorToastActive(true);
      setIsCopying(false);
    }
  }

  /* validators */
  const emailValidationErrorString = 'Ogiltig e-postadress';
  const validateEmail = () => {
    if(data.email && !data.email.match(/(.+)@(.+)\.(.+)/)) {
      return setEmailValidationError(emailValidationErrorString);
    }
    setEmailValidationError(null);
  }

  const secondaryActions = [
    { 
      content : 'Ta bort', 
      destructive : true, 
      onAction : confirmDeleteItem 
    }
  ]

  if(definition.canCopy !== false) {
    secondaryActions.push({
      content: 'Kopiera',
      accessibilityLabel: 'Kopiera',
      onAction : confirmCopyItem,
    });
  }

  const userLevels = [
    {label: 'Välj åtkomstnivå', value: ''},
    {label: 'Administratör', value: 'admin'},
    {label: 'Poweruser', value: 'poweruser'},
    {label: 'Superuser', value: 'superuser'},
  ]

  const PrimaryPageAction = pageAction;
  const pAction = PrimaryPageAction ? (<PrimaryPageAction {...props} hasChanges={hasChanges} saveButtonDisabled={saveButtonDisabled} id={id} data={data}/>) : null;

  const saveAction = {
    onAction: save,
    loading: isSaving,
    disabled: saveButtonDisabled,
  }
  
  const resetTranslation = (field, language) => {
    if(language === 'sv') return;
    let translations = JSON.parse(JSON.stringify(data.translations || {}))
    delete translations[language][`custom_${field}`];
    translations[language][field] = data.translations[language][`original_${field}`];
    if(field === 'description') {
      setQuillDescription(translations[language][field]);
    }
    setTimeout(() => {
      setData(data => ({ ...data, translations }));
    }, 150);
  }

  return(
    <>
      <Page
        primaryAction={pAction}
        title={`${verb} ${singularName}`}
        backAction={{content: pluralName, onAction : goToPreviousUrl }}
      >
        {hasChanges && !isLoading && (
          <ContextualSaveBar
            message={contextualSaveBarMessage}
            saveAction={saveAction}
            discardAction={authState.isAuthenticated && ({
              content : 'Ångra',
              disabled : isSaving,
              onAction: () => history.push(`/${dataType}`),
            })}
          />
        )}
        {isLoading && (skeletonPage)}
        {!isLoading && (
          <>
            {header && (
              <>
                <Layout>
                  <Layout.Section>
                    {header}
                  </Layout.Section>
                </Layout>
                <br />
              </>
            )}
            <Layout>
              <Layout.Section>
                <LegacyCard
                  title='Basinformation'
                >
                  <LegacyCard.Section>
                    <FormLayout>
                      {definition.name && (
                        <TextField 
                          label='Namn'
                          requiredIndicator
                          helpText='Internt namn, syns ej i appen eller på webben'
                          value={data.name}
                          onChange={v => handleDataChange(v, 'name')}
                        />
                      )}
                      {definition.userName && (
                        <TextField 
                          readOnly={definition.userName.readOnly && id}
                          disabled={definition.userName.readOnly && id}
                          label='Användarnamn'
                          requiredIndicator
                          helpText='Användarnamn kan inte ändras'
                          value={data.userName}
                          onChange={v => handleDataChange(v, 'userName')}
                        />
                      )}
                      {definition.givenName && (
                        <TextField 
                          label='Förnamn'
                          requiredIndicator
                          helpText='Förnamn'
                          value={data.givenName}
                          onChange={v => handleDataChange(v, 'givenName')}
                        />
                      )}
                      {definition.familyName && (
                        <TextField 
                          label='Efternamn'
                          requiredIndicator
                          helpText='Efternamn'
                          value={data.familyName}
                          onChange={v => handleDataChange(v, 'familyName')}
                        />
                      )}
                      {definition.email && (
                        <TextField 
                          label='E-post'
                          autoComplete='off'
                          requiredIndicator
                          helpText='E-postadress'
                          onBlur={validateEmail}
                          error={emailValidationError}
                          value={data.email}
                          type='email'
                          onChange={v => handleDataChange(v, 'email')}
                        />
                      )}
                      {definition.title && (
                        <TextField 
                          verticalContent={selectedLanguage !== 'sv' && (
                            <>
                              {(data.translations || {})[selectedLanguage]?.custom_title && (
                                <Text color="negative">
                                  Denna översättning har modifierats från den automatiska översättningen.
                                  <button style={{border: 0, background: 'white', color: 'blue', padding: 0, cursor: 'pointer'}} onClick={e => {
                                    resetTranslation('title', selectedLanguage)
                                  }}>Återställ</button>
                                </Text>  
                              )}
                              {!(data.translations || {})[selectedLanguage]?.custom_title && getTranslatedValue('title') && (
                                <Text color="success">
                                  Denna text är automatiskt översatt
                                </Text>  
                              )}
                              {!(data.translations || {})[selectedLanguage]?.custom_title && getTranslatedValue('title') === '' && (
                                <Text color="success">
                                  Denna text kommer att översättas automatiskt när du sparar.
                                </Text>  
                              )}
                            </>
                          )}
                          label='Titel'
                          requiredIndicator={definition.title.required}
                          helpText='Det titel som syns i appen och på webben'
                          value={getTranslatedValue('title')}
                          onChange={v => handleDataChange(v, 'title')}
                        />
                      )}
                      {definition.tagLine && (
                        <>
                          <TextField 
                            verticalContent={selectedLanguage !== 'sv' && (
                              <>
                                {(data.translations || {})[selectedLanguage]?.custom_tagLine && (
                                  <Text variation="warning">
                                    Denna översättning har modifierats från den automatiska översättningen.
                                    <button style={{border: 0, background: 'white', color: 'blue', padding: 0, cursor: 'pointer'}} onClick={e => {
                                      resetTranslation('tagLine', selectedLanguage)
                                    }}>Återställ</button>
                                  </Text>  
                                )}
                                {!(data.translations || {})[selectedLanguage]?.custom_tagLine && getTranslatedValue('tagLine') && (
                                  <Text variation="success">
                                    Denna text är automatiskt översatt
                                  </Text>  
                                )}
                                {!(data.translations || {})[selectedLanguage]?.custom_tagLine && getTranslatedValue('tagLine') === '' && (
                                  <Text variation="success">
                                    Denna text kommer att översättas automatiskt när du sparar.
                                  </Text>  
                                )}
                              </>
                            )}
                            label='Tagline'
                            requiredIndicator={definition.tagLine.required}
                            helpText={'En kort tagline'}
                            value={getTranslatedValue('tagLine')}
                            onChange={v => handleDataChange(v, 'tagLine')}
                          />
                        </>
                      )}
                      {definition.subTitle && (
                        <TextField 
                          label='Underrubrik'
                          requiredIndicator={definition.subTitle.required}
                          value={data.subTitle}
                          onChange={v => handleDataChange(v, 'subTitle')}
                        />
                      )}
                      {definition.contactName && (
                        <TextField 
                          label='Kontaktperson'
                          requiredIndicator={definition.contactName.required}
                          value={data.contactName}
                          onChange={v => handleDataChange(v, 'contactName')}
                          helpText="Vid frågor om innehåller behöver ha en kontaktperson vi kan ställa frågor till"
                        />
                      )}
                      {definition.contactInformation && (
                        <TextField 
                          multiline={true}
                          label='Kontaktinformation'
                          requiredIndicator={definition.contactInformation.required}
                          value={data.contactInformation}
                          onChange={v => handleDataChange(v, 'contactInformation')}
                          helpText="Telefon, e.post o.s.v"
                        />
                      )}
                      {definition.visible && (
                        <>
                        <Checkbox 
                          checked={data.visible === true} 
                          onChange={checked => handleDataChange(checked, 'visible')} 
                          helpText="Ej kryssad ruta betyder att informationen inte syns i appen eller på webben, lämna okryssad för t.ex utkast"
                          label={definition.visible.label || 'Synlig i app/webb'}
                        />
                        </>
                      )}
                      {definition.openWithoutInfoDialog && (
                        <>
                        <Checkbox 
                          checked={data.openWithoutInfoDialog === true} 
                          onChange={checked => handleDataChange(checked, 'openWithoutInfoDialog')} 
                          helpText="Ej kryssad ruta betyder att länken öppnas direkt utan informationsfönstret"
                          label={definition.openWithoutInfoDialog.label || 'Öppna direkt'}
                        />
                        </>
                      )}
                      {definition.unverified && (
                        <Checkbox 
                          checked={data.unverified} 
                          onChange={checked => handleDataChange(checked, 'unverified')} 
                          label={definition.unverified.label || 'Overifierat'}
                          helpText="Kryssad ruta betyder att informationen kommer från ett tips och är en kontrollerad"
                        />
                      )}
                      
                    </FormLayout>
                  </LegacyCard.Section>
                </LegacyCard>
                {definition.password && id && (
                  <LegacyCard
                    title="Lösenord"
                    primaryFooterAction={{
                      content : 'Ändra',
                      loading : isChangingPassword,
                      onAction : changePassword,
                      disabled : !password || !passwordConfirmation || (password !== passwordConfirmation)
                    }}
                  >
                    <LegacyCard.Section>
                      <FormLayout>
                        <TextField 
                          label='Nytt lösenord'
                          helpText='Ange nytt lösenord'
                          value={password}
                          type='password'
                          onChange={setPassword}
                        />
                        <TextField 
                          label='Nytt lösenord igen'
                          helpText='Ange det nya lösenordet igen'
                          type='password'
                          value={passwordConfirmation}
                          onChange={setPasswordConfirmation}
                          error={passwordConfirmation && (password !== passwordConfirmation)}
                        />
                      </FormLayout>
                    </LegacyCard.Section>
                  </LegacyCard>
                )}
                {(definition.description || (definition.webSite || definition.facebookUrl || definition.instagramUrl || definition.links)) && (
                  <LegacyCard
                    title={definition.description.label || 'Beskrivning'}
                  >
                    {(definition.description && definition.description.type === 'rich') && (
                      <>
                        {selectedLanguage !== 'sv' && (
                          <LegacyCard.Section>
                            {(data.translations || {})[selectedLanguage]?.custom_description && (
                              <Text color="warning">
                                Denna översättning har modifierats från den automatiska översättningen.
                                <br />
                                <button style={{border: 0, background: 'white', color: 'blue', padding: 0, cursor: 'pointer'}} onClick={e => {
                                  resetTranslation('description', selectedLanguage)
                                }}>Återställ</button>
                              </Text>  
                            )}
                            {!(data.translations || {})[selectedLanguage]?.custom_description && (
                              <Text color="success">
                                Denna text är automatiskt översatt
                              </Text>  
                            )}
                          </LegacyCard.Section>
                        )}
                        <LegacyCard.Section>
                        <ReactQuill 
                            modules={{ 
                              toolbar: [
                                ['bold', 'italic','strike'],
                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                ['clean'] 
                              ]
                            }}
                            style={{ marginBottom : 4 }}
                            value={quillDescription}
                            onChange={descriptionChanged}
                          >
                          </ReactQuill>
                          <Text color='subdued'>
                            {definition.description.helpText || 'Längre beskrivning, syns i appen och på hemsidan'}
                          </Text>
                        </LegacyCard.Section>
                      </>
                    )}
                    {(definition.description && definition.description.type === 'string') && (
                      <LegacyCard.Section>
                        <TextField
                          value={getTranslatedValue('description')}
                          label={definition.description.inputLabel || definition.description.label}
                          requiredIndicator={definition.description.required}
                          multiline={true}
                          onChange={value => handleDataChange(value, 'description')}
                          helpText={definition.description.helpText || 'Längre beskrivning, syns i appen och på hemsidan'}
                        />
                      </LegacyCard.Section>
                    )}
                    {definition.webSite && (
                      <LegacyCard.Section>
                        <FormLayout>
                          <TextField
                            type="url"
                            error={isInvalidUri}
                            label='Hemsida (Frivilligt)'
                            value={data.webSite}
                            helpText='Länk till ev. hemsida, syns i appen och på webben'
                            onChange={v => handleWebsiteChange(v) }
                            onBlur={validateWebSite}
                          />
                          {isInvalidUri && (
                            <span style={{ color : 'red'}}>Fel, måste vara en fullständig webadress http(s)://aaaa.*</span>
                          )}
                        </FormLayout>
                      </LegacyCard.Section>
                    )}
                    {definition.facebookUrl && (
                      <LegacyCard.Section>
                        <FormLayout>
                          <TextField
                            type="url"
                            error={isInvalidFacebookUri}
                            label='Facebook (Frivilligt)'
                            value={data.facebookUrl}
                            helpText='Länk till ev. facebook, syns i appen och på webben'
                            onChange={v => handleFacebookChange(v) }
                            onBlur={validateFacebook}
                          />
                          {isInvalidFacebookUri && (
                            <span style={{ color : 'red'}}>Fel, måste vara en fullständig webadress http(s)://aaaa.*</span>
                          )}
                        </FormLayout>
                      </LegacyCard.Section>
                    )}
                    {definition.facebookAppUrl && (
                      <LegacyCard.Section>
                        <FormLayout>
                          <TextField
                            type="url"
                            error={isInvalidFacebookUri}
                            label='Facebook App Url (Frivilligt)'
                            value={data.facebookAppUrl}
                            helpText='Länk till ev. facebook-app, fb://profile/xxxxxxxxxxxx'
                            onChange={v => handleFacebookAppChange(v) }
                            onBlur={validateFacebookApp}
                          />
                          {isInvalidFacebookAppUri && (
                            <span style={{ color : 'red'}}>Fel, måste vara en fullständig webadress fb://*</span>
                          )}
                        </FormLayout>
                      </LegacyCard.Section>
                    )}
                    {definition.instagramUrl && (
                      <LegacyCard.Section>
                        <FormLayout>
                          <TextField
                            type="url"
                            error={isInvalidInstagramUri}
                            label='Instagram (Frivilligt)'
                            value={data.instagramUrl}
                            helpText='Länk till ev. instagram, syns i appen och på webben'
                            onChange={v => handleInstagramChange(v) }
                            onBlur={validateInstagram}
                          />
                          {isInvalidInstagramUri && (
                            <span style={{ color : 'red'}}>Fel, måste vara en fullständig webadress http(s)://aaaa.*</span>
                          )}
                        </FormLayout>
                      </LegacyCard.Section>
                    )}
                    {definition.instagramAppUrl && (
                      <LegacyCard.Section>
                        <FormLayout>
                          <TextField
                            type="url"
                            error={isInvalidInstagramUri}
                            label='Instagram App Url (Frivilligt)'
                            value={data.instagramAppUrl}
                            helpText='Länk till ev. instagram-app, instagram://user?username=xxxxxxxxx'
                            onChange={v => handleInstagramAppChange(v) }
                            onBlur={validateInstagramApp}
                          />
                          {isInvalidInstagramAppUri && (
                            <span style={{ color : 'red'}}>Fel, måste vara en fullständig webadress instagram://*</span>
                          )}
                        </FormLayout>
                      </LegacyCard.Section>
                    )}
                    {(definition.payloadData && definition.payloadData.type === 'string') && (
                      <LegacyCard.Section>
                        <TextField
                          value={data.payloadData}
                          multiline={true}
                          onChange={value => handleDataChange(value, 'payloadData')}
                          helpText={definition.payloadData.helpText || 'Längre beskrivning, syns i appen och på hemsidan'}
                        />
                      </LegacyCard.Section>
                    )}
                  </LegacyCard>
                )}
                {definition.links && (
                  <LinksEditor 
                    title='Länkar'
                    language={selectedLanguage}
                    links={data.links}
                    onChange={handleLinksChanged}
                  />
                )}
                {definition.media && (
                  <MediaUpload 
                    title='Bilder'
                    files={data.media}
                    onChange={handleMediaChanged}
                  />
                )}
                {definition.location && (
                  <LegacyCard
                    title='Karta'
                  >
                    <LegacyCard.Section>
                      <Checkbox 
                        label='Jag vill ange en plats (Frivilligt)' 
                        checked={useLocation}
                        onChange={useLocationChanged}
                      />
                    </LegacyCard.Section>
                    {useLocation === true && (
                      <LocationSelector 
                        value={data.location}
                        address={data.address}
                        onChange={(lngLat, address) => handleLocationChange(lngLat, address) } 
                      />
                    )}
                  </LegacyCard>
                )}
              </Layout.Section>
              <Layout.Section secondary>
                {id && (
                  <>
                    <LegacyCard title="Språk">
                      <LegacyCard.Section>
                        <Select 
                          label="Välj språk"
                          options={languages}
                          onChange={handleLanguageChange}
                          value={selectedLanguage}
                        />
                      </LegacyCard.Section>
                    </LegacyCard>
                    <LegacyCard title="Information">
                      <LegacyCard.Section>
                        Skapad: {new Date(data.createdAt).toLocaleString()}
                      </LegacyCard.Section>
                    </LegacyCard>
                  </>
                )}
                {(definition.level) && (
                  <LegacyCard
                    title="Användarnivå"
                  >
                    <LegacyCard.Section>
                      <Select
                        onChange={handleUserLevelChange}
                        value={data.level}
                        options={userLevels}
                      />
                    </LegacyCard.Section>
                  </LegacyCard>
                )}
                {(definition.tags || (definition.organisations && (definition.organisations.userLevel === undefined || (definition.organisations.userLevel === data.level))) || definition.facilities || definition.featured) && (
                  <LegacyCard
                    title="Taggar"
                  >
                    {definition.tags && (
                      <LegacyCard.Section>
                        <TagsSelector dataType={definition.tags.dataType} onChange={handleTagsChanged} selectedTags={data.tags} />
                      </LegacyCard.Section>
                    )}
                    {definition.organisations && (definition.organisations.userLevel === undefined || (definition.organisations.userLevel === data.level)) && (
                      <LegacyCard.Section title='Verksamheter'>
                        <TagsSelector dataType={definition.organisations.dataType} onChange={handleOrganisationTagsChanged} selectedTags={data.organisations} />
                      </LegacyCard.Section>
                    )}
                    {definition.facilities && (
                      <LegacyCard.Section title='Anläggningar'>
                        <TagsSelector dataType={definition.facilities.dataType} onChange={handleFacilityTagsChanged}  selectedTags={data.facilities} />
                      </LegacyCard.Section>
                    )}
                    {definition.featured && (
                      <LegacyCard.Section>
                        <Checkbox 
                          checked={data.featured} 
                          onChange={checked => handleDataChange(checked, 'featured')} 
                          label='Lista som featured' 
                          helpText='Kryssad ruta betyder att evenmanget listas högst upp i appen'
                        />
                      </LegacyCard.Section>
                    )}
                  </LegacyCard>
                )}
                {definition.exposureSettings && (
                  <PushExposureInfo 
                    push={data} 
                    onTagsChanged={handleTagsChanged}
                    onOrganisationsChanged={handleOrganisationTagsChanged} 
                    onFacilitiesChanged={handleFacilityTagsChanged}
                    onChange={handleExposureSettings}
                  />             
                )}
                {definition.date && (
                  <DateSelector 
                    ref={dateSelector}
                    title={definition.date.label || 'Datum'}
                    selectedDates={selectedDates}
                    hasEndDate={definition.date.hasEndDate}
                    onChange={handleDateChanged}
                  />
                )}
                {definition.recurringPattern && (
                  <RecurringDateSelector
                    title={definition.recurringPattern.label || 'Återkommande'}
                    isEnabled={data.hasRecurringDate}
                    pattern={data.recurringPattern}
                    onChange={handleRecurringPatternChange}
                  />
                )}
                {definition.publishDate && (
                  <DateSelector 
                    title={definition.publishDate.label || 'Datum'}
                    selectedDates={selectedPublishDates}
                    hasEndDate={definition.publishDate.hasEndDate}
                    onChange={handlePublishDateChanged}
                  />
                )}
              </Layout.Section>
            </Layout>
          </>
        )}
        {errorToastActive && (<Toast content={errorToastMessage} error onDismiss={toggleErrorToastActive} />)}
        {successToastActive && (<Toast content={successToastMessage} onDismiss={toggleSuccessToastActive} />)}
        {!isLoading && id && (
          <>
            <br />
            <PageActions 
              secondaryActions={secondaryActions}
            />
            <Modal
              open={deleteModalVisible}
              onClose={e => setDeleteModalVisible(false)}
              title={'Ta bort'}
              primaryAction={{
                content : 'Ta bort',
                disabled : isDeleting,
                loading : isDeleting,
                onAction : deleteItem
              }}
              secondaryActions={
                {
                  content : 'Avbryt',
                  onAction : e => setDeleteModalVisible(false)
                }
              }
            >
              <Modal.Section>
                <Text>Vill du verkligen ta bort, detta går inte att ångra.</Text>
              </Modal.Section>
            </Modal>
            <Modal
              open={copyModalVisible}
              onClose={e => setCopyModalVisible(false)}
              title={'Kopiera'}
              primaryAction={{
                content : 'Kopiera',
                disabled : isCopying,
                loading : isCopying,
                onAction : copyItem
              }}
              secondaryActions={
                {
                  content : 'Avbryt',
                  onAction : e => setCopyModalVisible(false)
                }
              }
            >
              <Modal.Section>
                <Text>Vill du kopiera detta {singularName}?</Text>
              </Modal.Section>
            </Modal>
          </>
        )}
      </Page>
    </>
  )

}

export { CreateDataObject, DefaultPushPageAction, DefaultAdminPageAction }