import { Text } from '@shopify/polaris';

import Moment from 'react-moment';
import 'moment/locale/sv';

const calendarStrings = {
  lastDay : '[Igår kl] HH:mm',
  sameDay : '[Idag kl] HH:mm',
  nextDay : '[Imorgon kl] HH:mm',
  lastWeek : 'L [kl] HH:mm',
  nextWeek : 'dddd [kl] HH:mm',
  sameElse : 'L [kl] HH:mm'
};

const calendarStringsNoTime = {
  lastDay : '[Igår]',
  sameDay : '[Idag]',
  nextDay : '[Imorgon]',
  lastWeek : '[förra] dddd',
  nextWeek : 'dddd',
  sameElse : 'L'
};

const DateString = ({children, showTime}) => {
  const strings = showTime === false ? calendarStringsNoTime : calendarStrings;
  return(
    <Text>
      <Moment locale="sv" calendar={strings}>{children}</Moment>
    </Text>
  )
}

export { DateString }