import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Text, Toast } from '@shopify/polaris';

import { DataList } from "../screens/DataList"
import { restApi } from '../providers/restApi';

const DataObjectList = ({levels, dataType, pushDataType}) => {
  
  const history = useHistory();

  const [showCreatePushModal, setShowCreatePushModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [pushName, setPushName] = useState(undefined);
  const [id, setId] = useState(undefined);
  const [isCreated, setIsCreated] = useState(undefined);
  
  const [showSuccesToast, setShowSuccesToast] = useState(false);
  const [createSuccessMessage, setCreateSuccessMessage] = useState();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [createErrorMessage, setCreateErrorMessage] = useState('');

  const createPushAndNavigate = async () => {
    setIsCreating(true);
    try {
      let data = {}
      data[pushDataType || dataType.substr(0, dataType.length - 1)] = { id : id };
      console.log('data', data);
      let push = await restApi.put('/push', { name : pushName, title : pushName, payloadData : JSON.stringify(data)});
      setCreateSuccessMessage('Pushnotis skapad');
      setShowSuccesToast(true);
      setTimeout(() => {
        history.push(`/push/${push.id}`);
      }, 2000)
      setIsCreated(true);
    } catch(e) {
      setCreateErrorMessage(e.message);
      setShowErrorToast(true);
    }
    setIsCreating(false);
  }

  const cancelCreatePush = () => {
    setShowCreatePushModal(false);
  }

  const confirmCreatePush = ({id, name}) => {
    setPushName(name);
    setId(id);
    setShowCreatePushModal(true);
  }

  const actions = [
    {
      content : 'Skicka notis',
      onAction : confirmCreatePush
    }
  ]

  return(
    <>
      <DataList 
        dataType={dataType}
        levels={levels}
        shortcutActions={actions}
      />
      <Modal
        title='Skapar pushnotis'
        onClose={e => setShowCreatePushModal(false)}
        onDismiss={e => setShowCreatePushModal(false)}
        open={showCreatePushModal}
        primaryAction={{ content : 'Skapa', onAction : createPushAndNavigate, loading : isCreating, disabled : isCreating || isCreated }}
        secondaryActions={{ content : 'Avbryt', onAction : cancelCreatePush, disabled : isCreating || isCreated }}
      >
        <Modal.Section>
          <Text>Vill du skapa en pushnotis för <Text fontWeight='strong'>{pushName}</Text></Text>
        </Modal.Section>
      </Modal>
      {showErrorToast && (
        <Toast error onDismiss={e => setShowErrorToast(false)} content={createErrorMessage} />
      )}
      {showSuccesToast && (
        <Toast onDismiss={e => setShowSuccesToast(false)} content={createSuccessMessage} />
      )}
    </>
  )

}

export { DataObjectList }