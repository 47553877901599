import { useState, useEffect, useCallback } from 'react';
import { Avatar, ResourceItem, Text, ResourceList, Filters, EmptyState, Layout, LegacyCard, HorizontalStack, Pagination, ChoiceList } from '@shopify/polaris';
import { useHistory, useLocation } from 'react-router-dom';

import { DateString } from './DateString';
import { restApi } from '../providers/restApi';
import { Tag } from './Tag';

const aggregationTypes = ['tags'];

const ListDateItem = ({ date }) => {

  if(!date) return('- inget datum satt -');

  var startDate = new Date(date?.start?.stringValue).toLocaleDateString();
  var endDate = new Date(date?.end?.stringValue).toLocaleDateString();

  if(startDate === endDate) {
    return(
      <div style={{ display : 'flex', flexDirection : 'column'}}>
        <div style={{ display : 'flex', flexDirection : 'row', gap: '4px'}}>
          Dag: <DateString showTime={false}>{date.start?.stringValue}</DateString> 
        </div>
        {date.useStartTime && (
          <div style={{ display : 'flex', flexDirection : 'row', alignItems : 'center', gap: '4px'}}>
            Tid: <span>{date.useStartTime && new Date(date.start?.stringValue).toLocaleTimeString().substring(0, 5)}</span> 
            {date.useEndTime && (
              <span>
              - {new Date(date.end?.stringValue).toLocaleTimeString().substring(0, 5)}
              </span>
            )}
          </div>
        )}
        {!date.useStartTime && (
          <span>Heldag</span>
        )}
      </div>
    )
  } else {
    return(
      <div>
        <div style={{ display : 'flex', flexDirection : 'row', gap: '4px'}}>
          Datum: <DateString showTime={false}>{date.start?.stringValue}</DateString> till
          <DateString showTime={false}>{date.end?.stringValue}</DateString>
        </div>
        {date.useStartTime && (
          <div style={{ display : 'flex', flexDirection : 'row', alignItems : 'center', gap: '4px'}}>
            Tid: <span>{date.useStartTime && new Date(date.start?.stringValue).toLocaleTimeString().substring(0, 5)}</span> 
            {date.useEndTime && (
              <span>
              - {new Date(date.end?.stringValue).toLocaleTimeString().substring(0, 5)}
              </span>
            )}
          </div>
        )}
        {!date.useStartTime && (
          <span>Heldag</span>
        )}
      </div>
    )
  }  
}

const DataObjectList = ({ canCreate, shortcutActions, dataType, renderItem, singularName, pluralName }) => {

  const { search : searchParams } = useLocation();

  const getDefaultStatus = () => {
    const query = new URLSearchParams(searchParams);
    const status = query.get('status') || '';
    return(status);
  }

  const getDefaultVisible = () => {
    const query = new URLSearchParams(searchParams);
    const visible = query.get('visible') || '';
    return(visible);
  }
  
  const getDefaultQueryValue = () => {
    const query = new URLSearchParams(searchParams);
    const queryValue = query.get('query') || '';
    return(queryValue);
  }

  const getDefaultPageFrom = () => {
    const query = new URLSearchParams(searchParams);
    const pageFrom = parseInt(query.get('pageFrom') || 0);
    return(pageFrom);
  }

  const getDefaultPageSize = () => {
    const query = new URLSearchParams(searchParams);
    const pageSize = parseInt(query.get('pageSize') || 10);
    return(pageSize);
  }

  const pageFrom = getDefaultPageFrom();
  const pageSize = getDefaultPageSize();
  const status = getDefaultStatus();
  const visible = getDefaultVisible();
  const query = getDefaultQueryValue();

  const history = useHistory();
 
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryValue, setQueryValue] = useState(query);
  const [numHits, setNumHits] = useState(0);

  const appliedFilters = [];
  const filters = [];

  const setPageFrom = (pageFrom) => {
    const currentSearchParams = new URLSearchParams(searchParams);
    if(!pageFrom)
      currentSearchParams.delete('pageFrom');
    else
      currentSearchParams.set('pageFrom', pageFrom);
    history.replace({
      pathname: `/${dataType}`,
      search: currentSearchParams.toString()
    })
  }

  const setStatus = (status) => {
    const currentSearchParams = new URLSearchParams(searchParams);
    if(!status)
      currentSearchParams.delete('status');
    else
      currentSearchParams.set('status', status);
    history.replace({
      pathname: `/${dataType}`,
      search: currentSearchParams.toString()
    })
  }

  const setVisible = (visible) => {
    const currentSearchParams = new URLSearchParams(searchParams);
    if(!visible)
      currentSearchParams.delete('visible');
    else
      currentSearchParams.set('visible', visible);
    history.replace({
      pathname: `/${dataType}`,
      search: currentSearchParams.toString()
    })
  }

  const setFilterByQueryValue = useCallback((query) => {
    const currentSearchParams = new URLSearchParams(searchParams);
    if(!query)
      currentSearchParams.delete('query');
    else
      currentSearchParams.set('query', query);
    history.replace({
      pathname: `/${dataType}`,
      search: currentSearchParams.toString()
    })
  }, [dataType, history, searchParams]);

  const handleStatusChange = (value) => {
    setStatus(value.join(','))
  }

  const handleVisibleChange = (value) => {
    setVisible(value.join(','))
  }
  
  const handleStatusRemove = () => {
    setStatus([].join(','));
  }

  const handleVisbleRemove = () => {
    setVisible([].join(','));
  }
  
  const clearFilters = () => {   
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.delete('status');
    currentSearchParams.delete('query');
    currentSearchParams.delete('visible');
    history.push({
      pathname: `/${dataType}`,
      search: currentSearchParams.toString()
    });
  }
  
  const statusArray = status ? status.split(',') : [];
  const visibleArray = visible ? visible.split(',') : [];
  
  if(['events'].indexOf(dataType) !== -1) {    
    filters.push({
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            {label: 'Avslutade', value: 'ended'},
            {label: 'Pågående & kommande', value: 'ongoing'}
          ]}
          selected={statusArray || []}
          onChange={handleStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    })
  }

  if(['events', 'organisations'].indexOf(dataType) !== -1) {
    filters.push(
    {
      key: 'visible',
      label: 'Synlighet',
      filter: (
        <ChoiceList
          title="Synliga"
          titleHidden
          choices={[
            {label: 'Synliga', value: 'visible-true'},
            {label: 'Ej synliga', value: 'visible-false'},
          ]}
          selected={visibleArray || []}
          onChange={handleVisibleChange}
          allowMultiple
        />
      ),
      shortcut: true,
    })
  }

  if (!isEmpty(statusArray)) {
    if(statusArray.length > 0) {
      var label = statusArray.map(s => s === 'ended' ? 'Avslutade' : 'Pågående, kommande').join(', ');      
      appliedFilters.push({
        key : "status",
        label: label,
        onRemove: handleStatusRemove,
      });
    }
  }

  if (!isEmpty(visibleArray)) {
    if(visibleArray.length > 0) {
      appliedFilters.push({
        key : "visible",
        label: visibleArray.map(s => s === 'visible-true' ? 'Synliga' : 'Ej synliga').join(', '),
        onRemove: handleVisbleRemove,
      });
    }
  }


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let statusArray = status.split(',');     
      let visibleArray = visible.split(',');     
      let sort = [{ "createdAt" : "desc" }];
      if(dataType === 'events') {
        sort = [{ "date.start.intValue" : "desc" }];
      }
      let filter = [];      
      let showEnded = statusArray.indexOf("ended") > -1;
      let showUpcoming = statusArray.indexOf("ongoing") > -1;
      let showVisible = visibleArray.indexOf("visible-true") > -1;
      let showHidden = visibleArray.indexOf("visible-false") > -1;
      if(showUpcoming && !showEnded)
        filter.push({ "range": { "date.end.intValue": { "gte" : new Date().valueOf() }}});
      if(showEnded && !showUpcoming)
        filter.push({ "range": { "date.end.intValue": { "lte" : new Date().valueOf() }}});
      if(showHidden && !showVisible)
        filter.push({ "term": { "visible": false }});
      if(showVisible && !showHidden)
        filter.push({ "term": { "visible": true }});
      if(query)
        filter.push({ 
          bool: {
            should: [
              {
                wildcard: {
                  "name.keyword": {
                    value: `*${query}*`,
                    case_insensitive : true
                  }
                },
              },
              {
                wildcard: {
                  "organisations.name.keyword": {
                    value: `*${query}*`,
                    case_insensitive : true
                  }
                }
              }
            ]
          }
        })
      let { Hits, NumHits } = await restApi.post(`/${dataType}/query`, { sort, filter, from: pageFrom, size : pageSize });
      if(aggregationTypes.indexOf(dataType) > -1) {
        let tagsQuery = { tags: Hits.map(t => ({ name: t.name })) };        
        let { buckets  : eventBuckets} = await restApi.post(`/events/${dataType}`, tagsQuery);
        Hits = Hits.map(tag => ({
          ...tag,
          apa : tag.name,
          numEvents : eventBuckets.filter(b => b.key === tag.name)[0] || { doc_count : 0 }
        }))        
      }
      setItems(Hits);
      setNumHits(NumHits);
      setIsLoading(false)
    })();
  }, [dataType, query, pageFrom, pageSize, status, visible]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterByQueryValue(queryValue)
    }, 700)
    return(() => {
      clearTimeout(timeout);
    })
  }, [queryValue, setFilterByQueryValue])

  const createItem = () => {
    history.push(`/${dataType}/create`)
  }

  const filterControl = (
    <Filters
      queryValue={queryValue}
      onQueryChange={setQueryValue}
      onQueryClear={e => setQueryValue('')}
      onClearAll={e => { clearFilters(); }}
      filters={filters}
      appliedFilters={appliedFilters}
    />
  );

  let emptyStateMarkup = (appliedFilters.length === 0 && queryValue === '' && items.length === 0) ? (
      <EmptyState
        heading={`Skapa ${singularName}`}
        action={{content: `Skapa ${singularName}`, onAction : createItem }}
        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
      >
    </EmptyState>
  ) : undefined;

  if(emptyStateMarkup && canCreate === false) {
    emptyStateMarkup = (<EmptyState
        heading={`Inga ${pluralName}`}
        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
      >
    </EmptyState>);
  }

  function defaultRenderItem(item) {
    const {id, unverified, tags, organisations, date, name, media, numFollowers, numEvents } = item;
    const image = (media || []).length > 0 ? media[0].downloadUrl : null;
    const avatar = <Avatar customer size="medium" source={image} />;
    const url = `/#/${dataType}/${id}`;
    const allTags = [...(tags || []).map(t => `#${t.name}`), ...(organisations || []).map(o => o.name)];
    return (
      <ResourceItem
        id={id}
        url={url}
        media={avatar}
        accessibilityLabel={`Visa detaljer för ${name}`}
        verticalAlignment="center"
        shortcutActions={(shortcutActions || []).map(action => ({
          ...action,
          onAction : () => { action.onAction(item) }
        }))}
      >
        <div style={{ flex : 1, display : 'flex', justifyContent : 'flex-start', alignItems : 'center', flexDirection : 'row'}}>
          <div style={{ display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'flex-start'}}>
            {unverified && (
              <Text variant='heading3' color='subdued'>Overifierat</Text>
            )}
            <Text variant='heading3' fontWeight="bold">{name}</Text>
            {dataType === 'event' && (<ListDateItem date={date} />)}
            <div>
              <HorizontalStack gap="1" style={{marginLeft : 20}}>
                {numFollowers !== undefined && (
                    <Text color="success">{numFollowers} följer</Text>
                )}
                {numEvents !== undefined && (
                    <Text color="success">{numEvents.doc_count} evenemang</Text>
                )}
              </HorizontalStack>
            </div>
          </div>
          <div style={{ marginLeft : 20 }}>
            {allTags && (
              <HorizontalStack wrap gap="2">
                {allTags.map((tag) => {
                  return(
                    <Tag key={tag} type='tag' value={tag} />
                  )
                })}
              </HorizontalStack>
            )}
          </div>
        </div>
      </ResourceItem>
    );
  }

  return (
    <Layout>
      <Layout.Section>
        <LegacyCard>
          <ResourceList
            emptyState={emptyStateMarkup}
            items={items}
            loading={isLoading}
            renderItem={renderItem || defaultRenderItem}
            filterControl={filterControl}
            resourceName={{singular: singularName || 'file', plural: pluralName || 'files'}}
          />
          <LegacyCard.Section>
            <Pagination
              hasPrevious={pageFrom > 0}
              label={`Visar ${pageFrom + 1} till ${Math.min(numHits, pageFrom + pageSize)} av ${numHits}`}
              onPrevious={() => {
                setPageFrom(pageFrom - pageSize)
              }}
              hasNext={(pageFrom+pageSize) < numHits}
              onNext={() => {
                setPageFrom(pageFrom + pageSize)
              }}
            />
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  );

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

}

export { DataObjectList }