import { useState } from 'react';
import { LegacyCard, Checkbox, Select, HorizontalStack } from "@shopify/polaris"


const validTypes = [
  { label : 'Varje vecka', value : 'weekly' },
  { label : 'Varannan vecka', value : 'bi-weekly' },
  { label : 'Varje månad', value : 'monthly' },
  { label : 'Varannan månad', value : 'bi-monthly' },
  { label : 'Varje år', value : 'yearly' },
]

const validDays = [
  { label : 'Måndag', value : '1' },
  { label : 'Tisdag', value : '2' },
  { label : 'Onsdag', value : '3' },
  { label : 'Torsdag', value : '4' },
  { label : 'Fredag', value : '5' },
  { label : 'Lördag', value : '6' },
  { label : 'Söndag', value : '7' },
]

const validMonths = [
  { label : 'Januari', value : '0' },
  { label : 'Febrari', value : '1' },
  { label : 'Mars', value : '2' },
  { label : 'April', value : '3' },
  { label : 'Maj', value : '4' },
  { label : 'Juni', value : '5' },
  { label : 'Juli', value : '6' },
  { label : 'Augusti', value : '7' },
  { label : 'September', value : '8' },
  { label : 'Oktober', value : '9' },
  { label : 'November', value : '10' },
  { label : 'December', value : '11' },
]

const validDates = [
  { label : '1', value : '1' },
  { label : '2', value : '2' },
  { label : '3', value : '3' },
  { label : '4', value : '4' },
  { label : '5', value : '5' },
  { label : '6', value : '6' },
  { label : '7', value : '7' },
  { label : '8', value : '8' },
  { label : '9', value : '9' },
  { label : '10', value : '10' },
  { label : '11', value : '11' },
  { label : '12', value : '12' },
  { label : '13', value : '13' },
  { label : '14', value : '14' },
  { label : '15', value : '15' },
  { label : '16', value : '16' },
  { label : '17', value : '17' },
  { label : '18', value : '18' },
  { label : '19', value : '19' },
  { label : '20', value : '20' },
  { label : '21', value : '21' },
  { label : '22', value : '22' },
  { label : '23', value : '23' },
  { label : '24', value : '24' },
  { label : '25', value : '25' },
  { label : '26', value : '26' },
  { label : '27', value : '27' },
  { label : '28', value : '28' },
  { label : '29', value : '29' },
  { label : '30', value : '30' },
  { label : '31', value : '31' }
]

const RecurringDateSelector = (props) => {
  
  const initialDay = new Date().getDay();
  const initialMonth = new Date().getMonth() + 1;
  
  const { isEnabled, pattern, onChange } = props;
  const [enabled, setEnabled] = useState(isEnabled);
  const [recurringPattern, setRecurringPattern] = useState(pattern || { type : 'monthly', days : [`${initialDay}`], dayOfMonth : `${initialDay}`, monthOfYear : `${initialMonth}` });

  const handleEnabledChanged = (value) => {
    setEnabled(value)
    if(typeof(onChange) == "function") onChange(value, recurringPattern)
  }

  const handleTypeChange = (value) => {
    setRecurringPattern(p => ({ ...p, type : value }))
    if(typeof(onChange) == "function") onChange(enabled, { ...recurringPattern, type : value })
  }

  const handleDaysChanged = (day, checked) => {
    let newDays = [...recurringPattern.days];
    if(!checked)
      newDays.splice(newDays.indexOf(day), 1);
    else
      newDays.push(day);
    setRecurringPattern(p => ({ ...p, days : newDays }))
    if(typeof(onChange) == "function") onChange(enabled, { ...recurringPattern, days : newDays })
  }

  const handleDateChange = (dayOfMonth) => {
    setRecurringPattern(p => ({ ...p, dayOfMonth }))
    if(typeof(onChange) == "function") onChange(enabled, { ...recurringPattern, dayOfMonth })
  }

  const handleMonthChange = (monthOfYear) => {
    setRecurringPattern(p => ({ ...p, monthOfYear }))
    if(typeof(onChange) == "function") onChange(enabled, { ...recurringPattern, monthOfYear })
  }
  
  return(
    <LegacyCard>
      <LegacyCard.Section>
        <Checkbox label='Återkommande' checked={enabled} onChange={handleEnabledChanged} />
      </LegacyCard.Section>
      {enabled && (
        <>
          <LegacyCard.Section>
            <Select 
              value={recurringPattern.type} 
              onChange={handleTypeChange}
              options={validTypes}
            />
          </LegacyCard.Section>
          {recurringPattern.type.indexOf('weekly') > -1 && (
            <LegacyCard.Section>
              <HorizontalStack>
                {validDays.map(d => {
                  return(
                    <Checkbox key={d.value} label={d.label} checked={recurringPattern.days.indexOf(d.value) > -1} onChange={e => handleDaysChanged(d.value, e)} />
                  )
                })}
                </HorizontalStack>
            </LegacyCard.Section>
          )}
          {(recurringPattern.type.indexOf('monthly') > -1 || recurringPattern.type.indexOf('yearly') > -1) &&  (
            <LegacyCard.Section>
              <Select 
                value={recurringPattern.dayOfMonth} 
                onChange={handleDateChange}
                options={validDates}
              />
            </LegacyCard.Section>
          )}
          {recurringPattern.type.indexOf('yearly') > -1 &&  (
            <LegacyCard.Section>
              <Select 
                value={recurringPattern.monthOfYear} 
                onChange={handleMonthChange}
                options={validMonths}
              />
            </LegacyCard.Section>
          )}
        </>
      )}
    </LegacyCard>
  )
}

export { RecurringDateSelector }