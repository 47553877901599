import { useState, useEffect, useCallback, useRef } from 'react';

import { LegacyCard, FormLayout, TextField, Text, Autocomplete } from '@shopify/polaris';

import { MapBoxContainer } from "./MapBoxContainer";
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

const mapBoxToken = 'pk.eyJ1IjoidG9rZmFyYnJvcm4iLCJhIjoiY2t0YTJhcXVsMDZsejJ2bXlnM3ljbWNxMiJ9.HuQa3RQqf_orM5Gij3TVfQ';

const getDefaultLocation = async (navigator) => {
  return new Promise((done, fail) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        done({ lon : location.coords.longitude, lat : location.coords.latitude })
      }, (e) => {
        fail(e);
      });
    } else {
      return({ lon : 17.2, lat : 61.3 })
    }
  })
}

const LocationSelector = ({ onChange, value, address : defaultAddress }) => {

  const mapRef = useRef(null);
  
  const [defaultLocation, setDefaultLocation ] = useState(value || { lon : 17.2, lat : 61.3 });
  const [defaultMarkerLocation, setDefaultMarkerLocation ] = useState(value || { lon : 17.2, lat : 61.3 });
  
  const [longitude, setLongitude] = useState(defaultLocation.lon.toString());
  const [latitude, setLatitude] = useState(defaultLocation.lat.toString());
  const [address, setAddress] = useState(defaultAddress || '');
  const [addressOptions, setAddressOptions] = useState([]);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  
  const handleChange = useCallback((location, adress) => {
    if(typeof(onChange) === 'function') {
      onChange(location, adress);
    }
  }, [onChange]);

  useEffect(() => {
    (async () => {
      if(value === undefined) {
        let location = await getDefaultLocation(navigator);
        setLongitude(location.lon.toString());
        setLatitude(location.lat.toString());
        setDefaultLocation(location);
        setDefaultMarkerLocation(location);
        handleChange(location)
      }
    })();
  }, [value, handleChange]);

  const getPlaces = useCallback((address) => {
    const geocodingClient = mbxGeocoding({
      accessToken: mapBoxToken,
    });
    return geocodingClient
      .forwardGeocode({
        query: address,
        countries: ['se'],
        limit: 5,
        proximity : [parseFloat(longitude.replace(',', '.')), parseFloat(latitude.replace(',', '.'))]
      })
      .send()
      .then((response) => {
        const match = response.body;
        const autoCompletes = match.features.map((f,i) => {
          return({ value : { ...f, key : `ac_${i}`}, label : f.place_name })
        })
        return(autoCompletes);
      })
  }, [longitude, latitude]);

  useEffect(() => {
    const findAddressTimer = setTimeout(() => {
      if(address) {
        setIsLoadingAddresses(true);
        getPlaces(address).then((autoCompletes) => {
          setAddressOptions(autoCompletes)  
          setIsLoadingAddresses(false);
        })
      }
    }, 650);
    return(() => {
      clearTimeout(findAddressTimer);
    })
  }, [address, getPlaces])


  const handleLocationChange = (location, setAddress) => {
    setLongitude(location[0].toString())
    setLatitude(location[1].toString())
    setDefaultMarkerLocation({ lon : location[0], lat : location[1] });
    handleChange({ lon : location[0], lat : location[1] }, setAddress || address);
  }

  const handleLongitudeChange = (value) => {
    setLongitude(value);
    setDefaultMarkerLocation({ lon : value, lat : latitude });
    handleChange({ lon : value, lat : latitude }, address);
  }

  const handleLatitudeChange = (value) => {
    setLatitude(value);
    setDefaultMarkerLocation({ lon : longitude, lat : value });
    handleChange({ lon : longitude, lat : value }, address);
  }

  const updateAddressOptions = (value) => {
    setAddress(value)
  }

  const handleAdressChange = (value) => {
    let option = addressOptions.filter(o => o.value.id === value[0])[0]
    let location = [
      option.value.center[0],
      option.value.center[1]
    ]
    mapRef.current.setViewPort({
      latitude: parseFloat(location[1]),
      longitude: parseFloat(location[0]),
      zoom: 12
    })
    setAddress(option.label)
    handleLocationChange(location, option.label);
  }

  const addressField = (
    <Autocomplete.TextField
      onChange={updateAddressOptions}
      value={address}
      placeholder="Sök adress"
    />
  )

  return(
    <>      
      <LegacyCard.Section>
        <FormLayout>
          <Autocomplete
            selected={[]}
            options={addressOptions.map(o => ({ value : o.value.id, label : o.label }))}
            textField={addressField}
            onSelect={handleAdressChange}
            listTitle="Föreslagna taggar"
            loading={isLoadingAddresses}
          />
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <MapBoxContainer
          ref={mapRef}
          onChange={handleLocationChange}
          center={[parseFloat(defaultMarkerLocation.lon), parseFloat(defaultMarkerLocation.lat)]}
          marker={[parseFloat(defaultMarkerLocation.lon), parseFloat(defaultMarkerLocation.lat)]}
        />
        <Text color='subdued'>Klicka på en plats för att sätta ut en markör</Text>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label='Longitud'
              requiredIndicator
              type='number'
              step={0.001}
              onChange={handleLongitudeChange}
              value={longitude}
            /> 
            <TextField
              label='Latitud'
              requiredIndicator
              type='number'
              step={0.001}
              onChange={handleLatitudeChange}
              value={latitude}
            /> 
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard.Section>
    </>
  )
}

export { LocationSelector }