import { useContext } from 'react';

import { DataObjectList } from '../components/DataObjectList';
import { Page } from '@shopify/polaris';

import { useHistory } from 'react-router-dom';
import { AuthContext } from '../providers/AuthContext';

const singularNames = {
  'events' : 'evenemang',
  'organisations' : 'verksamhet',
  'facilities' : 'anläggning',
  'push' : 'notiser',
  'link' : 'länk'
}

const pluralNames = {
  'events' : 'evenemang',
  'organisations' : 'verksamheter',
  'facilities' : 'anläggningar',
  'push' : 'notiser',
  'links' : 'länkar'
}

const DataList = ({ levels, dataType, shortcutActions, singularName, pluralName }) => {
  
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const { level } = authContext.state.user ? authContext.state.user : { level : 'none' };

  let canCreate = true;

  if(levels && levels.indexOf(level) === -1) {
    canCreate = false;
  }

  let primaryAction = canCreate ? {
    content : `Skapa ${singularNames[dataType] || 'nytt'}`,
    onAction : e => { history.push(`/${dataType}/create`) }
  } : null;


  return(
    <Page primaryAction={primaryAction}>
      <DataObjectList
        dataType={dataType}
        canCreate={canCreate}
        singularName={singularName || singularNames[dataType]}
        pluralName={pluralName || pluralNames[dataType]}
        shortcutActions={shortcutActions}
      />
    </Page>
  )

}

export { DataList }