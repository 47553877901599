
import { useHistory } from 'react-router-dom';

import { DataList } from "../screens/DataList"

const PushList = () => {

  const history = useHistory();

  const showHistory = ({id}) => {
    history.push(`/push/${id}/history`)
  }

  const actions = [
    {
      content : 'Historik',
      onAction : showHistory
    }
  ]

  return(
    <>
      <DataList 
        dataType='push' 
        shortcutActions={actions}
      />
    </>
  )
}


export { PushList }