import { useReducer, useEffect, useCallback } from 'react';

import { AppProvider } from '@shopify/polaris';
import svTranslations from '@shopify/polaris/locales/sv.json';

import 'react-quill/dist/quill.snow.css'; // ES6

import './App.css';

import { HashRouter as Router } from 'react-router-dom';

import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

import { AuthContext, authReducer, defaultAuthState, getUserData } from './providers/AuthContext';

import { restApi } from './providers/restApi';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'

import { initFacebookSdk } from './facebook';

import { AppFrame } from './AppFrame';

library.add(faCalendarDay)

Amplify.configure(awsconfig);

function App() {

  const [authState, authDispatch] = useReducer(authReducer, defaultAuthState);

  useEffect(() => {
    initFacebookSdk()
    .then((response) => {
      console.log('Facebook SDK initialized', response)
    })
  }, [authState, authDispatch]);

  useEffect(() => {
    if(!authState.isAuthenticated) {
      // check if logged in
      Auth.currentAuthenticatedUser().then(() => {
        authDispatch({ type : 'setIsAuthenticated', value: true });
      }).catch((error) => {
        authDispatch({ type : 'setIsAuthenticated', value: false });
      });
    }
    if(authState.isAuthenticated) {
      Auth.currentAuthenticatedUser().then(() => {
        restApi(`/admin/me`)
        .then((admin) => {
          if(admin.fb_access_token) {
            console.log('Got token from /admin/me');
            authDispatch({ type : 'setFacebookAccessToken', token: admin.fb_access_token });
          } else {
            authDispatch({ type : 'setIsFacebookAuthenticated', value: false });
          }
          authDispatch({  type: 'setUser', value: getUserData(admin) })
        })
      })
      .catch((error) => {
        authDispatch({ type : 'setIsAuthenticated', value : false });
      });  
    }
  }, [authState.isAuthenticated]); 

  const disconnectFacebook = useCallback(() => {
    restApi.delete('/facebook/fb-token')
    .then((response) => {
      console.log('Delete Facebook Token Response', response);
      window.FB.logout(function(response) {
        console.log('FB Logout', response);
        authDispatch({ type: 'setIsFacebookAuthenticated', value: false });
        authDispatch({ type: 'setFacebookAccessToken', value: null });
      });
    })
  }, [authDispatch])

  useEffect(() => {
    if(authState.facebookAccessToken) {
      console.log('Verifying Facebook Access Token', authState.facebookAccessToken);
      restApi.get('/facebook/me')
      .then((response) => {
        console.log('/facebook/me Response', response);
        if(response.error) {
          disconnectFacebook();
        } else {
          authDispatch({ type : 'setIsFacebookAuthenticated', value: true });
        }
      })
      .catch((error) => {
        console.log('me error', error);
        disconnectFacebook();
      });
    }
  }, [authState.facebookAccessToken, authDispatch, disconnectFacebook]);

  return (
    <AuthContext.Provider value={{ state: authState, dispatch : authDispatch}}>
      <AppProvider i18n={svTranslations}>
        <Router hashType='slash'>
          <AppFrame />
        </Router>
    </AppProvider>
    </AuthContext.Provider>
  );
  
}

export default App;
