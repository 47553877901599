import { useEffect, useState, useContext } from 'react';

import { LegacyCard, Page, ResourceItem, ResourceList, Text, Modal, Avatar } from '@shopify/polaris';
import { AuthContext } from '../providers/AuthContext';

import { restApi } from '../providers/restApi';

import { useHistory } from 'react-router-dom';
import { ConnectPageModal } from '../components/facebook/ConnectPageModal';
import { DisconnectPageModal } from '../components/facebook/DisconnectPageModal';

const Home = () => {

  const history = useHistory();

  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);

  const [facebookPages, setFacebookPages] = useState([]);
  const [isLoadingFacebookAccounts, setIsLoadingFacebookAccounts] = useState(false);
  const [disconnectFacebookModalVisible, setDisconnectFacebookModalVisible] = useState(false);
  const [connectPageModalVisible, setConnectPageModalVisible] = useState(false);
  const [disconnectPageModalVisible, setDisconnectPageModalVisible] = useState(false);
  const [pageToConnect, setPageToConnect] = useState(null);

  const { user } = authState;

  const fbLogin = () => {
    window.FB.login(function({ authResponse }) {
      // handle the response
      if(authResponse) {
        const { accessToken: access_token } = authResponse;
        restApi.post('/facebook/get-long-lived-token', { access_token })
        .then((tokenResponse) => {
          const { access_token } = tokenResponse;
          restApi.post('/facebook/fb-token', { access_token })
          .then((response) => {
            waitForESPropagation();
          });
        });        
      }
    }, {scope: 'public_profile,pages_show_list,pages_manage_metadata'})
  }

  //,pages_show_list,pages_manage_posts,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_engagement

  const waitForESPropagation = () => {
    setTimeout(() => {
      restApi.get('/admin/me')
      .then((response) => {
        const { fb_access_token } = response;
        if(fb_access_token) {
          authDispatch({ type: 'setIsFacebookAuthenticated', value: true });
          authDispatch({ type: 'setFacebookAccessToken', value: fb_access_token });
        } else {
          waitForESPropagation();
        }
      }, 2000);
    });
  }

  useEffect(() => {
    if(authState.isFacebookAuthenticated) {
      setIsLoadingFacebookAccounts(true);
      restApi
      .get('/facebook/pages')
      .then((response) => {
        setFacebookPages(response.data);
        setIsLoadingFacebookAccounts(false);
      });
    }
  }, [authState.isFacebookAuthenticated]);

  const facebookPageIsConnected = (page) => {
    const subscribed_to_facebook_pages = user.subscribed_to_facebook_pages || [];
    return(subscribed_to_facebook_pages.find((subscribedPage) => subscribedPage.pageId === page.id) ? true : false);
  }

  const disconnectFacebook = () => {
    restApi.delete('/facebook/fb-token')
    .then((response) => {
      console.log('Delete Facebook Token Response', response);
      window.FB.api('/me/permissions', 'delete', function(response) {
        console.log('FB Permissions Delete', response);
      });
      window.FB.logout(function(response) {
        console.log('FB Logout', response);
        authDispatch({ type: 'setIsFacebookAuthenticated', value: false });
        authDispatch({ type: 'setFacebookAccessToken', value: null });
        setDisconnectFacebookModalVisible(false);
      });
    })
  }

  const toggleConnectFacebookPage = (page) => {
    setPageToConnect(page);
    if(facebookPageIsConnected(page)) {
      setDisconnectPageModalVisible(true);
    } else {
      setConnectPageModalVisible(true);
    }
  }

  const manageFacebookPage = (page) => {
    if(facebookPageIsConnected(page)) {
      history.push(`/facebook/${page.id}`);
    }
  }

  return(
    <Page>
      {/* connect modal */}
      <ConnectPageModal 
        visible={connectPageModalVisible} 
        close={e => setConnectPageModalVisible(false)} 
        page={pageToConnect}
      />
      {/* connect modal */}
      <DisconnectPageModal
        visible={disconnectPageModalVisible}
        close={e => setDisconnectPageModalVisible(false)}
        page={pageToConnect}
      />
      {/* disconnect facebook modal */}
      <Modal
        open={disconnectFacebookModalVisible}
        title="Koppla bort Facebook"
        onClose={() => setDisconnectFacebookModalVisible(false)}
        primaryAction={{
          content: 'Koppla bort',
          onAction: () => disconnectFacebook(),
        }}
        secondaryActions={[
          {
            content: 'Avbryt',
            onAction: () => setDisconnectFacebookModalVisible(false),
          },
        ]}
      >
        <Modal.Section>
          <Text>
            Om du kopplar bort Facebook kommer vi inte längre att kunna hämta evenemang från Facebook automatiskt.
          </Text>
        </Modal.Section>
      </Modal>
      {!authState.isFacebookAuthenticated && authState.isFacebookAuthenticated !== null && (
      <LegacyCard        
        title="Koppla ihop med Facebook"
        primaryFooterAction={{
          content: 'Koppla ihop med Facebook',
          onAction: () => { fbLogin() }
        }}
      >
        <LegacyCard.Section>
          <Text>
            Koppla ihop med Facebook för att automatiskt hämta evenemang.
          </Text>
        </LegacyCard.Section>
      </LegacyCard>
      )}
      {authState.isFacebookAuthenticated && authState.isFacebookAuthenticated !== null && (
      <LegacyCard        
        title="Koppla bort Facebook"
        primaryFooterAction={{
          content: 'Koppla bort Facebook',
          onAction: () => setDisconnectFacebookModalVisible(true)
        }}
      >
        <LegacyCard.Section>
          <Text>
            Koppla bort om du inte längre till använda tjänsten.
          </Text>
        </LegacyCard.Section>
      </LegacyCard>
      )}
      {authState.isFacebookAuthenticated && (
        <LegacyCard>
          <ResourceList
            loading={isLoadingFacebookAccounts}
            items={facebookPages}
            renderItem={(page) => {
              const media = <Avatar customer size="medium" name={"no name"} />;
              return(
                <ResourceItem
                  shortcutActions={[{ 
                    content: facebookPageIsConnected(page) ? 'Koppla bort' : 'Koppla', 
                    onAction: () => { toggleConnectFacebookPage(page) }
                  }]}
                  persistActions
                  id={page.id}
                  media={media}
                  onClick={() => { manageFacebookPage(page) } }
                  accessibilityLabel={`Koppla ihop ${page.name}`}
                >
                  <Text>
                    {page.name}
                  </Text>
                  <div>
                      {facebookPageIsConnected(page) && (
                        <Text color='success'>
                          <span>Kopplad</span>
                        </Text>
                      )}
                       {!facebookPageIsConnected(page) && (
                        <Text color='warning'>
                          <span>Ej Kopplad</span>
                        </Text>
                      )}
                  </div>
                </ResourceItem>
              )
            }}
          >
          </ResourceList>
        </LegacyCard>
      )}
    </Page>
  );
}


export { Home }