import { useState, useEffect, useCallback } from 'react';

import { EmptyState, Toast, Modal, Page, LegacyCard, SkeletonBodyText, ResourceList, ResourceItem, HorizontalStack, Text } from '@shopify/polaris';

import { useParams } from 'react-router-dom';

import { restApi } from '../providers/restApi';

import { DateString } from '../components/DateString';

const PushHistory = () => {
  
  const { id } = useParams();
  const [push, setPush] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isRemovingSchedule, setIsRemovingSchedule] = useState(false);
  const [history, setHistory] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [scheduleIdToRemove, setScheduleIdToRemove] = useState(false);
  const [removeScheduleModalVisible, setRemoveScheduleModalVisible] = useState(false);

  const [errorToastActive, setErrorToastActive] = useState(false);
  const [successToastActive, setSuccessToastActive] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [successToastMessage, setSuccessToastMessage] = useState('');
  const toggleErrorToastActive = () => { setErrorToastActive(!errorToastActive) }
  const toggleSuccessToastActive = () => { setSuccessToastActive(!successToastActive) }

  const toggleRemoveScheduleModal = (id) => {
    setScheduleIdToRemove(id);
    setRemoveScheduleModalVisible(!removeScheduleModalVisible);
  }

  const dateSort = (a, b) => {
    return(new Date(b.date).valueOf() - new Date(a.date).valueOf())
  }

  useEffect(() => {
    (async () => {
      let result = await restApi(`/push/failed`);
      if(result.Messages) {
        for(const message of result.Messages) {
          const { id } = JSON.parse(message.Body);
          const { MessageId, ReceiptHandle, Attributes } = message;
          const date = new Date(parseInt(Attributes.ApproximateFirstReceiveTimestamp)).toISOString();
          await restApi.put(`/push/${id}/failed/${MessageId}`, { Date : date });
          await restApi.post(`/push/failed/delete`, { ReceiptHandle });
          setHistory(history => {
            return([...history, ...[{ date, messageId : MessageId, failed : true }]])
          })
        }
      }
    })();
  }, []);

  const reload = useCallback((async () => {
    let push = await restApi.get(`/push/${id}`);
    setPush(push);
    setHistory(push.history.sort(dateSort))
    let schedule = await restApi.get(`/push/schedule`);
    const matchingSchedules = schedule.filter(s => {
      return((s.Tags.filter(t => t.Key === 'pushId')[0] || {}).Value === id)
    })
    setScheduled(matchingSchedules);
    setIsLoading(false);
  }), [id]);

  useEffect(() => {
    reload();
  }, [reload])

  const removeSchedule = async () => {
    setIsRemovingSchedule(true);
    try {
      let deleteResult = await restApi.delete(`/schedule/${scheduleIdToRemove}`);
      if(deleteResult === 'ok') {
        setSuccessToastMessage(`Schemaläggning borttagen`);
        setSuccessToastActive(true);
        reload();
        toggleRemoveScheduleModal();
      } else {
        throw deleteResult;
      }
    } catch(e) {
      setErrorToastMessage(e);
      setErrorToastActive(true);
    }
    setIsRemovingSchedule(false);
  }
  
  const renderItem = (item) => {
    const { date, messageId } = item;
    const sendResult = history.filter(h => h.sendResult).filter(h => h.messageId === messageId)[0];
    const failedResult = history.filter(h => h.failed).filter(h => h.messageId === messageId)[0];
    return(
      <ResourceItem>
        <HorizontalStack distribution='equalSpacing'>
          <Text><DateString>{date}</DateString> - {messageId}</Text>
          {sendResult && (
            <Text color='subdued'>{sendResult.sendResult.NumSentMessages} skickade, {sendResult.sendResult.NumFailedMessages} misslyckades</Text>
          )}
          {!sendResult && failedResult === undefined && (
            <Text color='success'>väntar på resultat...</Text>
          )}
          {!sendResult && failedResult !== undefined && (
            <Text color='success'>misslyckades</Text>
          )}
        </HorizontalStack>
      </ResourceItem>
    )
  }

  const renderScheduled = (item) => {
    const { Tags } = item;
    const scheduledAt = Tags.filter(t => t.Key === 'scheduledAt')[0];
    let str = scheduledAt.Value.replace(' ', 'T');
    let dateStr = new Date(`${str}.000Z`).toLocaleString();
    return(
      <ResourceItem
        shortcutActions={[
          {
            content : 'Ta bort',
            destructive : true,
            onAction : e => toggleRemoveScheduleModal(item.Name)
          }
        ]}
      >
        <HorizontalStack distribution='equalSpacing'>
          <Text>Schemalagd till {dateStr}</Text>
        </HorizontalStack>
      </ResourceItem>
    )
  }
  

  const removeScheduleAction = { content : 'Ta bort', onAction : removeSchedule, disabled : isRemovingSchedule, loading : isRemovingSchedule };
  
  const emptyScheduleStateMarkup = (
    <EmptyState
      heading={`Ingenting schemalagt`}
    >
    </EmptyState>
  )

  const emptyHistoryStateMarkup = (
    <EmptyState
      heading={`Ingenting skickat ännu`}
    >
    </EmptyState>
  )

  return(
    <Page
      title={push.name}
      backAction={{url : '/#/push'}}
    >
      <LegacyCard
        title='Schemalagda'
      >
        <LegacyCard.Section >
          {!isLoading && (
            <ResourceList
              emptyState={emptyScheduleStateMarkup}
              renderItem={renderScheduled}
              items={scheduled}
            />
          )}
          {isLoading && (
            <SkeletonBodyText />
          )}
        </LegacyCard.Section>
      </LegacyCard>
      <LegacyCard
        title='Push historik'
      >
        <LegacyCard.Section >
          {!isLoading && (
            <ResourceList 
              emptyState={emptyHistoryStateMarkup}
              renderItem={renderItem}
              items={push.history.filter(h => !h.sendResult && h.failed === undefined)}
            />
          )}
          {isLoading && (
            <SkeletonBodyText />
          )}
        </LegacyCard.Section>
      </LegacyCard>
      <Modal
        title='Ta bort schemaläggning'
        onClose={e => toggleRemoveScheduleModal()}
        onDismiss={e => toggleRemoveScheduleModal()}
        open={removeScheduleModalVisible}
        primaryAction={removeScheduleAction}
        secondaryActions={{ content : 'Avbryt', onAction : toggleRemoveScheduleModal, disabled : isRemovingSchedule }}
      >
        <Modal.Section>
          <Text>Ta bort schemaläggning?</Text>
        </Modal.Section>        
      </Modal>
      {errorToastActive && (<Toast content={errorToastMessage} error onDismiss={toggleErrorToastActive} />)}
      {successToastActive && (<Toast content={successToastMessage} onDismiss={toggleSuccessToastActive} />)}
    </Page>
  )
}


export { PushHistory }