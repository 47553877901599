import React, { useState, useImperativeHandle } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

const mapBoxToken = 'pk.eyJ1IjoidG9rZmFyYnJvcm4iLCJhIjoiY2t0YTJhcXVsMDZsejJ2bXlnM3ljbWNxMiJ9.HuQa3RQqf_orM5Gij3TVfQ';

const navControlStyle= {
  right: 10,
  top: 10
};

const MapBoxContainer = React.forwardRef((props, ref) => {

  const { marker, center, onChange } = props;

  const [viewport, setViewport] = useState({
    latitude: center[1],
    longitude: center[0],
    zoom: 12
  });

  const handleMapClick = (point) => {
    if(typeof(onChange) === 'function') {
      onChange(point.lngLat)
    }
  }

  useImperativeHandle(ref, () => {
    return({
      setViewPort : (viewPort) => {
        setViewport(viewPort)
      }
    })
  })

  return(
    <div
      style={{
        height: 400,
        marginBottom : 4,
        width: '100%',
        aspectRatio : 1
      }}
    >
      <ReactMapGL
        {...viewport}
        ref={ref}
        onViewportChange={(viewport) => setViewport(viewport)}
        mapboxApiAccessToken={mapBoxToken}
        width="100%"
        height="100%"
        onClick={handleMapClick}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <NavigationControl style={navControlStyle} />
        {marker && (
          <Marker
            longitude={marker[0]}
            latitude={marker[1]}
            offsetLeft={-24}
            offsetTop={-48}
          >
            <svg fill="blue" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
          </Marker>
        )}  
      </ReactMapGL>
    </div>
  )
})

export { MapBoxContainer }