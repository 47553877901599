import React from 'react';

const defaultAuthState = {
  isAuthenticated: null,
  isFacebookAuthenticated: null,
  facebookAccessToken: null,
  user: { 
    username: '' 
  }
}

const AuthContext = React.createContext(defaultAuthState);

const authReducer = (state, action) => {
  switch(action.type) {
    case 'setUser':
      return({ ...state, user: action.value })
    case 'setSubscribedFacebookPages':
      return({ ...state, user: { ...state.user, subscribed_to_facebook_pages: action.value }})
    case 'setIsAuthenticated':
      if(action.value === true)
        return({ ...state, isAuthenticated: action.value });
      else
        return({ ...state, isAuthenticated: action.value, user: { username: '' }});
    case 'setIsFacebookAuthenticated':
      return({ ...state, isFacebookAuthenticated: action.value });
    case 'setFacebookAccessToken':
      return({ ...state, facebookAccessToken: action.token });
    default:
      console.error('Invalid action for authReducer', action.type)
        return(state)
  }
}

const getUserData = (user) => {
  return({ 
    username: user.userName, 
    level: user.level, 
    givenName: user.givenName, 
    familyName: user.familyName,
    organisations: user.organisations,
    subscribed_to_facebook_pages: user.subscribed_to_facebook_pages,
  })
}

export { AuthContext, defaultAuthState, authReducer, getUserData }