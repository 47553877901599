import React, { useState, useImperativeHandle } from 'react';
import { LegacyCard, FormLayout, TextField, DatePicker, Checkbox, Text } from '@shopify/polaris';

const pad = (val) => {
  if(parseInt(val) < 10)
    return(`0${val}`)
  return(`${val}`);
}
/**
 * 
 * @param {{ title : string, selectedDates : { start : Date, end : Date, useEndTime : Boolean, }, hasEndDate: Boolean, onChange : function }} props 
 * @returns 
 */
const DateSelector = React.forwardRef(({ hasEndDate, title, selectedDates, onChange }, ref) => {

  const [_selectedDates, setSelectedDates] = useState({ start : new Date(selectedDates.start), end : new Date(selectedDates.end) })
  const [finalDates, setFinalDates] = useState({ start : new Date(selectedDates.start), end : new Date(selectedDates.end) } )

  const [year, setYear] = useState(_selectedDates.start.getFullYear());
  const [month, setMonth] = useState(_selectedDates.start.getMonth());

  const [useDateRange, setUseDateRange] = useState(_selectedDates.end.toLocaleDateString() !== _selectedDates.start.toLocaleDateString());
  const [useStartTime, setUseStartTime] = useState(selectedDates.useStartTime || false);
  const [useEndTime, setUseEndTime] = useState(selectedDates.useEndTime || false);

  const [startHoursString, setStartHoursString] = useState(pad(finalDates.start.getHours().toString()));
  const [startMinutesString, setStartMinutesString] = useState(pad(finalDates.start.getMinutes().toString()));

  const [endHoursString, setEndHoursString] = useState(pad(finalDates.end.getHours().toString()));
  const [endMinutesString, setEndMinutesString] = useState(pad(finalDates.end.getMinutes().toString()));

  const [startTimeError, setStartTimeError] = useState(undefined);
  const [endTimeError, setEndTimeError] = useState(undefined);

  useImperativeHandle(ref, () => ({
    hasValidDate : () => {
      return(startTimeError === undefined && endTimeError === undefined)
    }
  }));

  const handleUseRangeChanged = (checked) => {
    setUseDateRange(checked);
    if(!checked) {
      const { start } = _selectedDates;
      setSelectedDates({ start , end : start })
      const finalStart = new Date(start);
      const finalEnd = new Date(start);
      finalStart.setHours(finalDates.start.getHours());
      finalStart.setMinutes(finalDates.start.getMinutes());
      finalEnd.setHours(finalDates.end.getHours());
      finalEnd.setMinutes(finalDates.end.getMinutes());
      updateFinalDates({ start: finalStart, end : finalEnd, useEndTime })
    }
  }
    
  const handleUseStartTimeChanged = (checked) => {
    setUseStartTime(checked);
    const { start, end } = _selectedDates;
    setSelectedDates({ start , end })
    const finalStart = new Date(start);
    const finalEnd = new Date(end);
    finalStart.setHours(finalDates.start.getHours());
    finalStart.setMinutes(finalDates.start.getMinutes());
    finalStart.setMilliseconds(0);
    finalStart.setHours(checked ? finalStart.getHours() : 0);
    finalStart.setMinutes(checked ? finalStart.getMinutes() : 0);
    finalStart.setSeconds(checked ? finalStart.getSeconds() : 0);
    updateFinalDates({ start: finalStart, end : finalEnd, useEndTime, useStartTime : checked })
  }

  const handleUseEndTimeChanged = (checked) => {
    setUseEndTime(checked);
    const { start, end } = _selectedDates;
    setSelectedDates({ start , end })
    const finalStart = new Date(start);
    const finalEnd = new Date(end);
    finalStart.setHours(finalDates.start.getHours());
    finalStart.setMinutes(finalDates.start.getMinutes());
    finalStart.setMilliseconds(0);
    finalEnd.setHours(checked ? finalEnd.getHours() : 23);
    finalEnd.setMinutes(checked ? finalEnd.getMinutes() : 59);
    finalEnd.setSeconds(checked ? finalEnd.getSeconds() : 59);
    updateFinalDates({ start: finalStart, end : finalEnd, useStartTime, useEndTime : checked })
  }

  const updateFinalDates = (dates) => {
    setEndHoursString(pad(dates.end.getHours().toString()));
    setEndMinutesString(pad(dates.end.getMinutes().toString()));
    setStartMinutesString(pad(dates.start.getMinutes().toString()));
    setStartHoursString(pad(dates.start.getHours().toString()));
    setFinalDates(dates);
    if(typeof(onChange) === 'function') onChange(dates);
  }

  const handleMonthChange = (month, year) => {
    setMonth(month);
    setYear(year);
  }

  const handleSelectedDatesChanged = (dates) => {
    const { start, end } = dates;
    const finalStart = new Date(start);
    const finalEnd = new Date(end);
    finalStart.setHours(finalDates.start.getHours());
    finalStart.setMinutes(finalDates.start.getMinutes());
    finalEnd.setHours(finalDates.end.getHours());
    finalEnd.setMinutes(finalDates.end.getMinutes());
    updateFinalDates({ start: finalStart, end: finalEnd, useEndTime, useStartTime })
    setSelectedDates(dates);
  }

  const handleStartHourChange = () => {
    let h = parseInt(startHoursString);
    if(!isNaN(h)) {
      finalDates.start.setHours(Math.max(0, Math.min(23, h)));
      setStartTimeError(undefined);
      updateFinalDates({ ...finalDates });
    } else {
      setStartTimeError('Ogiltig tid');
    }
  }

  const handleStartMinuteChange = () => {
    let m = parseInt(startMinutesString);
    if(!isNaN(m)) {
      finalDates.start.setMinutes(Math.max(0, Math.min(59, m)));
      setStartTimeError(undefined);
      updateFinalDates({ ...finalDates });
    } else {
      setStartTimeError('Ogiltig tid');
    }
  }

  const handleEndHourChange = () => {
    let h = parseInt(endHoursString);
    if(!isNaN(h)) {
      finalDates.end.setHours(Math.max(0, Math.min(23, h)));
      setEndTimeError(undefined);
      updateFinalDates({ ...finalDates });
    } else {
      setEndTimeError('Ogiltig tid');
    }
  }

  const handleEndMinuteChange = () => {
    let m = parseInt(endMinutesString);
    if(!isNaN(m)) {
      finalDates.end.setMinutes(Math.max(0, Math.min(59, m)));
      setEndTimeError(undefined);
      updateFinalDates({ ...finalDates });
    } else {
      setEndTimeError('Ogiltig tid');
    }
  }  

  const { start, end } = finalDates;
  
  const showTimeError = (end.valueOf() < start.valueOf());

  return(
    <LegacyCard title={title}>
      <LegacyCard.Section>
        <DatePicker
          month={month}
          year={year}
          allowRange={useDateRange}
          onChange={handleSelectedDatesChanged}
          onMonthChange={handleMonthChange}
          weekStartsOn={1}
          selected={_selectedDates}
        />
      </LegacyCard.Section>
      {hasEndDate && (
        <LegacyCard.Section>
          <Checkbox label='Ange start- & slutdatum' checked={useDateRange} onChange={handleUseRangeChanged} />
        </LegacyCard.Section>
      )}
      <LegacyCard.Section>
        <Checkbox label='Ange starttid' checked={useStartTime} onChange={handleUseStartTimeChanged} />
      </LegacyCard.Section>
      {useStartTime && (
        <LegacyCard.Section
          title={`Starttid ${finalDates.start.toLocaleDateString()}`}
        >
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField value={startHoursString} type='text' onBlur={handleStartHourChange} onChange={setStartHoursString} placeholder="timme (HH)"/>
              <TextField value={startMinutesString} type='text' onBlur={handleStartMinuteChange} onChange={setStartMinutesString} placeholder="minut (MM)"/>
            </FormLayout.Group>
            {startTimeError && (<Text color='warning'>{startTimeError}</Text>)}
          </FormLayout>
        </LegacyCard.Section>
      )}
      {hasEndDate && (
        <>
          <LegacyCard.Section>
            <Checkbox label='Ange sluttid' checked={useEndTime} onChange={handleUseEndTimeChanged} />
          </LegacyCard.Section>
          {useEndTime && (
            <LegacyCard.Section
              title={`Sluttid ${finalDates.end.toLocaleDateString()}`}
            >
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField value={endHoursString} type='text' onBlur={handleEndHourChange} onChange={setEndHoursString} placeholder="timme (HH)"/>
                  <TextField value={endMinutesString} type='text' onBlur={handleEndMinuteChange} onChange={setEndMinutesString} placeholder="minut (MM)"/>
                </FormLayout.Group>
                {endTimeError && (<Text color='warning'>{endTimeError}</Text>)}
                {showTimeError && (<Text color='warning'>Sluttiden kan inte vara före starttiden</Text>)}
              </FormLayout>
            </LegacyCard.Section>
          )}
        </>
      )}
    </LegacyCard>
  )
})

export { DateSelector }