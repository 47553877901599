
import axios from 'axios';
import { settings } from '../settings';

import { Auth } from 'aws-amplify';

const restApi = axios.create({
  baseURL : settings.apiUrl
})

restApi.interceptors.request.use(async (config) => {
  let user = (await Auth.currentAuthenticatedUser().catch(e => (null)));
  if(user) {
    let token = (await Auth.currentSession()).getIdToken().getJwtToken();
    config.headers.Authorization = token;
  }
  return(config)
})

restApi.interceptors.response.use((response) => {  
  return(response.data)
}, (error) => {
  console.log('API Error', error, error.response.data.errorMessage);
  if(error && error.response && error.response.data && error.response.data.error) {
    return Promise.reject(error.response.data.error)
  }
  if(error && error.response && error.response.data && error.response.data.errorMessage) {
    return Promise.reject(error.response.data.errorMessage)
  }
  return(Promise.reject(error.message || 'API Error'));
})

export { restApi }