import { useState, useCallback, useEffect, useContext } from 'react';
import { Autocomplete, Tag, Box, VerticalStack, Text, HorizontalStack } from '@shopify/polaris';
import { restApi } from '../providers/restApi';
import { AuthContext } from '../providers/AuthContext';

const TagsSelector = ({ dataType, selectedTags, onChange }) => {

  const [deselectedOptions, setDeselectedOptions] = useState([])
  const [options, setOptions] = useState(deselectedOptions);
  const [allTags, setAllTags] = useState(deselectedOptions);
  const [selectedOptions, setSelectedOptions] = useState(selectedTags || []);
  const [inputValue, setInputValue] = useState('');
  const [isLoadingTags, setIsLoadingTags] = useState(true);

  const { state : authState } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      let tags = [];
      if(authState.isAuthenticated) {
        if(dataType === 'tags' || !dataType)
          tags = await restApi.get('/tags').then(r => r.Hits).then(r => { return(r); }).then(items => items.map(i => ({ value : { id : i.name, name : i.title }, label : i.title, followers : i.numFollowers })));
        else if(dataType)
          tags = await restApi.post(`/${dataType}/query`, { from : 0, size : 100 }).then(r => r.Hits).then(r => { return(r); }).then(items => items.map(i => ({ value : { id : i.id, name : i.title || i.name }, label : i.title || i.name, followers : i.numFollowers })));      
      } else {
        tags = await restApi.post(`/${dataType}/search`, { size : 50 }).then(r => r.Hits).then(r => { return(r); }).then(items => items.map(i => ({ value : { id : i.name, name : i.title || i.name }, label : i.title || i.name, followers : i.numFollowers })));
      }
      setAllTags(tags);
      setDeselectedOptions(tags.map(t => ({ value : t.value.id, label : t.label })));
      setOptions(tags.map(t => ({ value : t.value.id, label : (
        <VerticalStack vertical={'true'} spacing="extraTight">
          <Text>{t.label}</Text>
          <Text color="subdued"><small>{t.followers || 0} följare</small></Text>
        </VerticalStack>
      ) })));        
      setIsLoadingTags(false);
    })();
  }, [dataType, authState.isAuthenticated]);

  /*
  useEffect(() => {
    if(allTags && allTags.length > 0) {
      if(authState.isAuthenticated) {
        const { level : userLevel } = authState.user ? authState.user : { level : 'none' };
        if(userLevel === 'admin' && dataType === 'organisations') {
          const options = [allTags[0].value.id];
          setSelectedOptions(options);
          if(typeof(onChange) === 'function') 
            onChange(options.map(o => ({ id : o, name : allTags.filter(t => t.value.id === o)[0].label })));
        }
      }  
    }
  }, [dataType, allTags, authState, onChange])
  */
 
  useEffect(() => {
    if(selectedTags) {
      setSelectedOptions(selectedTags.map(t => t.id));
    }
  }, [selectedTags]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      );
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
      if(typeof(onChange) === 'function') 
        onChange(options.map(o => ({ id : o, name : allTags.filter(t => t.value.id === o)[0].label })));
    }
  , [selectedOptions, onChange, allTags]);

  const handleTagsChanged = (options) => {
    setSelectedOptions(options);
    if(typeof(onChange) === 'function') 
      onChange(options.map(o => ({ id : o, name : allTags.filter(t => t.value.id === o)[0].label })));
  }

  const tagsMarkup = selectedOptions.length > 0 ? selectedOptions.map((option) => {
    let tagLabel = typeof(option) === 'string' ? (allTags.filter(t => t.value.id === option)[0] || { label : option }).label : option.name;
    return (
      <Tag key={`option_${option.id || option}`} onRemove={removeTag(option)}>
        {tagLabel}
      </Tag>
    );
  }) : (<Text color='subdued'>Inga taggar valda</Text>);

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      placeholder="Tag, Verksamhet, Anläggning"
      autoComplete='off'
      disableautocomplete={true}
    />
  );

  return (
    <>
      <Autocomplete
        allowMultiple
        options={options}
        selected={selectedOptions}
        textField={textField}
        onSelect={handleTagsChanged}
        listTitle="Föreslagna taggar"
        loading={isLoadingTags}
      /><br />
      <Box>
        <HorizontalStack gap="1" spacing='extraTight'>{tagsMarkup}</HorizontalStack>
      </Box>
    </>
  );

}

export { TagsSelector }