import { useContext, useState, useCallback, useEffect } from 'react';

import { TopBar as ShopifyTopBar, ActionList, Text, Spinner, HorizontalStack } from '@shopify/polaris';

import { Tag } from './Tag';

import { useHistory } from 'react-router-dom';

import { AuthContext } from '../providers/AuthContext';
import { Auth } from 'aws-amplify';

import { restApi } from '../providers/restApi';

const TopBar = ({ toggleMobileNavigationActive }) => {

  const history = useHistory();

  const authContext = useContext(AuthContext);

  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dbSearchResults, setDbSearchResults] = useState([]);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [iSearching, setIsSearching] = useState(false);
  
  const goto = (url) => {
    history.push(url);
    setSearchValue('');
    setSearchActive(false);
  }

  const availableActions = [
    { content: 'Evenemang', onAction : e => goto('/events') }, 
    { content: 'Verksamheter', onAction : e => goto('/organisations') }, 
    { content: 'Anläggningar', onAction : e => goto('/facilities') }, 
    { content: 'Pushnotiser', onAction : e => goto('/push') }
  ]

  useEffect(() => {
    if(searchValue !== '') {
      let timeout = setTimeout(
        (async () => {
          setIsSearching(true);
          let keyword = searchValue.toLowerCase();
          const getQuery = (type) => {
            const query = {
              filter: [
                {
                  bool: {
                    should: [
                      {
                        wildcard: {
                          "name.keyword": {
                            value: `*${keyword}*`,
                            case_insensitive : true
                          }
                        },
                      },
                      {
                        wildcard: {
                          "organisations.name.keyword": {
                            value: `*${keyword}*`,
                            case_insensitive : true
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
            if(type === 'events') {
              query.sort = [
                {
                    "date.start.intValue": "desc"
                }
              ]
            }
            return query;
          }
          let eventsResult = await restApi.post('/events/query', getQuery('events'));
          let organisationsResult = await restApi.post('/organisations/query', getQuery('organisations'));
          let facilitiesResult = await restApi.post('/facilities/query', getQuery('facilities'));
          let pushResult = await restApi.post('/push/query', getQuery('push'));
          setDbSearchResults([...eventsResult.Hits, ...organisationsResult.Hits, ...facilitiesResult.Hits, ...pushResult.Hits]);
          setIsSearching(false);
      }), 500);
      return(() => {
        clearTimeout(timeout);
      })
    }
  }, [searchValue]);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const logout = async () => {
    await Auth.signOut();
    authContext.dispatch({ type : 'setIsAuthenticated', value : false })
  }

  const userMenuActions = [
    {
      items: [
        {
        content : 'Logga ut',
        onAction : logout
      }],
    },
  ];

  const initials = () => {
    if(authContext.state.isAuthenticated) {      
      if(authContext.state.user.familyName && authContext.state.user.givenName) {
        return(`${authContext.state.user.givenName[0]}${authContext.state.user.familyName[0]}`);
      } else {
        return(authContext.state.user.username.substr(0, 1));
      }
    }
  }

  let organisation = ((authContext.state.user?.organisations || [])[0] || { name : 'Verkstäderna' })
  const userMenuMarkup = authContext.state.isAuthenticated ? (
    <ShopifyTopBar.UserMenu
      actions={userMenuActions}
      name={authContext.state.user.username}
      detail={organisation.name}
      initials={initials()}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : null;

  let filteredActions = availableActions.filter(action => {
    if(searchValue.length === 0) return(true);
    return(action.content.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
  });
  
  filteredActions = [...filteredActions, ...dbSearchResults.map((r) => {
    let organisation = (r.organisations || []).length > 0 ? r.organisations[0].name.trim() : null;
    return({
      content : (
      <HorizontalStack>
        <Text>{`${r.name}`}</Text>
        {organisation && (<Tag type='tag' value={organisation} />)}
      </HorizontalStack>),
      onAction : e => goto(`/${r.type}s/${r.id}`)
    })
  })]
  if(iSearching)
    filteredActions.push({ content : (<div style={{ display : 'flex', justifyContent : 'center', alignItems : 'center'}}><Spinner size='small' /></div>) });

  const searchResultsMarkup = filteredActions.length === 0 ? (
    <ActionList items={[{ content : 'Hittade inget' }]}/>
  ) : 
  (
    <ActionList items={filteredActions} />
  );

  const searchFieldMarkup = (
    <ShopifyTopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Sök"
    />
  );

  return(
    <ShopifyTopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  )
}

export { TopBar }