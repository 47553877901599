import { useEffect, useState } from 'react';

import { LegacyCard, ResourceList, ResourceItem, Text, Modal, FormLayout, TextField } from '@shopify/polaris'; 

function AddLinkModal({ language, okButtonTitle, title, link, onClose, onAdd, active }) {

  const [url, setUrl] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    if(link) {
      setUrl(link.url);
      if(language !== 'sv' && language) {
        var t = link.translations;
        if(t && t[language] && t[language].name) {
          setName(t[language].name);
        }
      } else {
        setName(link.name);
      }
    }
  }, [link, language]);

  const handleAdd = () => {
    if(typeof(onAdd) == 'function') {
      if(onAdd({ url, name })) {
        setUrl('');
        setName('');
      }
    }
  }

  const handleClose = () => {
    if(typeof(onClose) == 'function') {
      setUrl('');
      setName('');
      onClose();
    }
  }

  const checkIfValidUri = () => {
    return(url && url.match('^http(s?)://(.+)\\.(.+)$'))
  }

  const isValidUri = url.length >= 4 && checkIfValidUri();

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        title={title || "Lägg till länk"}
        primaryAction={{
          content: okButtonTitle || 'Lägg till',
          disabled : (!name || !url || !isValidUri),
          onAction: handleAdd,
        }}
        secondaryActions={[
          {
            content: 'Avbryt',
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              value={name}
              onChange={setName}
              label="Namn"
              autoComplete="none"
            />
            <TextField
              value={url}
              error={!isValidUri}
              onChange={setUrl}
              label="URL"
              autoComplete="none"
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </>
  );
}

const LinksEditor = ({ language, title, links : _links, onChange }) => {

  const [links, setLinks] = useState(_links || []);
  const [addLinkFormVisible, setAddLinkFormVisible] = useState(false);
  const [editLinkFormVisible, setEditLinkFormVisible] = useState(false);
  const [editedLink, setEditedLink] = useState(undefined);
  const [selectedItems, setSelectedItems] = useState([]);
  
  const resourceName = {
    singular: 'länk',
    plural: 'länkar',
  };

  const handleLinksChanged = (links) => {
    if(typeof(onChange) == 'function')
      onChange([...links])
  }

  const promotedBulkActions = [
    {
      content: 'Ta bort',
      onAction: () => {
        setLinks(links => {
          let newLinks = links.filter(l => selectedItems.indexOf(l.url) === -1);
          handleLinksChanged(newLinks);
          setSelectedItems([]);
          return(newLinks)
        });
      }
    },
  ];

  const bulkActions = [
    {
      content: 'Ta bort',
      onAction: () => {
        setLinks(links => {
          let newLinks = links.filter(l => selectedItems.indexOf(l.url) === -1);
          handleLinksChanged(newLinks);
          setSelectedItems([]);
          return(newLinks)
        });
      }
    }
  ];

  function renderItem(item) {
    let {id, url, name} = item;
    if(language !== 'sv') {
      var t = item.translations || { };
      if(t[language] && t[language].name) 
        name = t[language].name;
    }
    return (
      <ResourceItem
        id={id}
        shortcutActions={[
          {
            content : 'Redigera',
            onAction : () => { editLink(item) }
          }
        ]}
      >
        <Text variant='heading3' fontWeight="strong">{name}</Text>
        <div>{url}</div>
      </ResourceItem>
    );
  }

  const showAddLinkForm = () => {
    setAddLinkFormVisible(true)
  }

  const closeAddLinkForm = () => {
    setAddLinkFormVisible(false)
  }

  const editLink = (link) => {
    setEditedLink({...link});
    showEditLinkForm()
  }

  const updateLink = (link) => {
    if(links.filter(l => l.url === link.url).length > 0 && editedLink.url !== link.url) {
      alert('URL´en finns redan i listan')
      return(false);
    }
    for(const l of links.filter(l => l.url === editedLink.url)) {
      if(language !== 'sv') {
        var t = l.translations || {};
        t[language] = {
          name: link.name,
          custom_name: true
        }
        link.translations = { ...t };
      } else {
        l.name = link.name;
      }
      l.url = link.url;
    }
    setLinks([...links]);
    handleLinksChanged([...links]);
    closeEditLinkForm();
    return(true);
  }

  const showEditLinkForm = () => {
    setEditLinkFormVisible(true);
  }

  const closeEditLinkForm = () => {
    setEditLinkFormVisible(false);
  }

  const addLink = (link) => {
    if(links.filter(l => l.url === link.url).length > 0) {
      alert('URL´en finns redan i listan')
      return(false);
    } 
    setLinks(links => {
      let newLinks = [...links, link];
      handleLinksChanged(newLinks);
      return(newLinks);
    });
    closeAddLinkForm();
    return(true);
  }

  const noLinksMarkup = (
    <div>
      Inga länkar tillagda
    </div>
  )

  return(
    <>
      <LegacyCard
        sectioned
        title={title || 'Länkar'}
        actions={[
          { 
            content : 'Lägg till',
            onAction : showAddLinkForm
          }
        ]}
      >
        <ResourceList
          resourceName={resourceName}
          items={links.map(l => ({ ...l, id : l.url }))}
          renderItem={renderItem}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          bulkActions={bulkActions}
          emptyState={noLinksMarkup}
        />
      </LegacyCard>
      <AddLinkModal language={language} active={addLinkFormVisible} onClose={closeAddLinkForm} onAdd={addLink}/>
      <AddLinkModal language={language} title="Ändra länk" okButtonTitle='Ändra' link={editedLink} active={editLinkFormVisible} onClose={closeEditLinkForm} onAdd={updateLink}/>
    </>
  )
}

export { LinksEditor }