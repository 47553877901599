

const MODE = {
  production : 'production',
  devel : 'devel'
}

const mode = MODE.production; // MODE.produ

const _settings = {}

_settings[MODE.production] = {
  apiUrl : 'https://pfnt1lsjs1.execute-api.eu-north-1.amazonaws.com/prod'
}

_settings[MODE.devel] = {
  apiUrl : 'https://pfnt1lsjs1.execute-api.eu-north-1.amazonaws.com/dev'
}

const settings = _settings[mode];

const facebookAppId = 975387583478166;

export { settings, mode, facebookAppId }