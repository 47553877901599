import React, { useState, useContext, useCallback, useEffect } from "react"

import { Modal, Text, Select, Toast } from "@shopify/polaris"

import { AuthContext } from '../../providers/AuthContext';
import { restApi } from '../../providers/restApi';

const ConnectPageModal = ({visible, close, page}) => {

  const [isConnectingPage, setIsConnectingPage] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [toastActive, setToastActive] = useState(false);

  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const { user } = authState;

  const toggleToastActive = useCallback(() => setToastActive((active) => !active), []);

  useEffect(() => {
    setAvailableOrganisations(user.organisations?.map((organisation) => {
      return {
        label: organisation.name,
        value: organisation.id
      }
    }) || []);
    if(user.organisations?.length > 0) {
      setSelectedOrganisation(user.organisations[0].id);
    }
  }, [user.organisations]);
  
  const handleOrganisationChange = (value) => {
    setSelectedOrganisation(value);
  }

  const didClickConnectPage = () => {
    setIsConnectingPage(true);
    restApi.post('/facebook/connect', { pageId: page.id, organisationId: selectedOrganisation })
      .then(() => {
        toggleToastActive();
        authDispatch({ type: 'setSubscribedFacebookPages', value: [...user.subscribed_to_facebook_pages, { pageId: page.id, organisationId: selectedOrganisation }] });
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setIsConnectingPage(false);
      });
    close();
  }

  const toastMarkup = toastActive ? (<Toast content="Sida kopplad" onDismiss={toggleToastActive} />) : null;

  return(
    <>
      {toastMarkup}
      <Modal
        open={visible}
        onClose={close}
        title="Koppla ihop sida"
        primaryAction={{
          content: 'Koppla',
          disabled: !selectedOrganisation || isConnectingPage,
          loading: isConnectingPage,
          onAction: didClickConnectPage,
        }}
        secondaryActions={[
          {
            content: 'Avbryt',
            disabled: isConnectingPage,
            onAction: close,
          },
        ]}
      >
        <Modal.Section>
          <Text>
            Om du kopplar sidan kommer vi att kunna hämta evenemang från sidan automatiskt.
          </Text>
        </Modal.Section>
        <Modal.Section>
          <Select
            label="Organisation som ska äga evenemangen"
            options={availableOrganisations}
            onChange={handleOrganisationChange}
            value={selectedOrganisation}
          />
        </Modal.Section>
      </Modal>
    </>
  )

}

export { ConnectPageModal }