import { useState, useCallback, useEffect } from 'react';

import { HorizontalStack, Thumbnail, LegacyCard, DropZone, Spinner, Text } from '@shopify/polaris'; 
import { NoteMinor, DeleteMajor } from '@shopify/polaris-icons';

import { restApi } from '../providers/restApi';

const FileUpload = ({ file, onRemove, onUploaded }) => {
  
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [isUploading, setIsUploading] = useState(false);
  const [_file, setFile] = useState(file);

  useEffect(() => {
    if(_file && !_file.downloadUrl) {
      (async () => {
        setIsUploading(true);
        const uploadData = await restApi.put('/image');
        await upload(uploadData, _file);
        const newFile = { id : uploadData.id, downloadUrl : uploadData.downloadUrl, type : _file.type, name : _file.name }
        setFile(newFile);
        onUploaded(newFile);
        setIsUploading(false);
      })();
    }
  }, [_file, onUploaded]);

  const upload = (data, blob) => {
    return new Promise((resolved) => {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open('PUT', data.url, true);
      if(xmlhttp.upload) {
        xmlhttp.upload.addEventListener('progress', (event) => {
          
        });
      }
      xmlhttp.onload = function () {        
        setTimeout(() => {
          resolved();
        }, 250)
      }
      xmlhttp.setRequestHeader('Content-Disposition', data.contentDisposition)
      xmlhttp.setRequestHeader('Content-Type', blob.type)
      xmlhttp.send(blob);
    })
  }

  let thumb = NoteMinor;
  if(validImageTypes.indexOf(file.type) > -1) {
    thumb =  file.downloadUrl || window.URL.createObjectURL(file)
  }
  
  return(
    <HorizontalStack alignment="center">
      <div
        style={{ position : 'relative', backgroundColor : '#eee', padding : 20, borderRadius : 10, display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center' }}
      >
        {isUploading && (
          <Spinner accessibilityLabel="Spinner example" size="large" />
        )}
        {_file.downloadUrl && (
          <>
            <div style={{ position : 'absolute', right : -5, top : -5 }}>
              <DeleteMajor onClick={e => onRemove(e, file)} width={18} color='#aaa' />
            </div>
            <Thumbnail
              size="medium"
              alt={file.name}
              source={thumb}
            />
            <span title={file.name} style={{ textOverflow: 'ellipsis', whiteSpace : 'nowrap', overflow : 'hidden', maxWidth : 80, maxHeight : 40 }}>{file.name}</span>
          </>
       )}
      </div>
    </HorizontalStack>
  )

}

const MediaUpload = ({ title, onChange, files : valueFiles }) => {

  const [files, setFiles] = useState(valueFiles || []);
  const [openFileDialog, setOpenFileDialog] = useState(false);

  const handleDropZoneDrop = useCallback(
    (dropFiles, _acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...dropFiles]),
    [],
  );

  const toggleOpenFileDialog = useCallback(
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    [],
  );

  const removeFile = (e, index) => {
    files.splice(index, 1);
    setFiles([...files]);
    if(typeof(onChange) === 'function') onChange([...files]);
    e.stopPropagation();
  }

  const handleFileUploaded = (file, index) => {
    files[index] = file;
    setFiles([...files]);
    if(typeof(onChange) === 'function') onChange([...files]);
  }

  const uploadedFiles = files.length > 0 && (
    <HorizontalStack>
      {files.map((file, index) => (
        <FileUpload key={`file_${index}`} file={file} onRemove={e => removeFile(e, index)} onUploaded={e => handleFileUploaded(e, index)} />
      ))}
    </HorizontalStack>
  );

  return (
    <LegacyCard
      title={title || 'Filer'}
      actions={[
        {
          content: `Ladda upp ${title}`,
          onAction: toggleOpenFileDialog,
        },
      ]}
    >
      <LegacyCard.Section>
        <DropZone
          openFileDialog={openFileDialog}
          onDrop={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          <div style={{ padding : 10}}>
          {uploadedFiles}
          </div>
        </DropZone>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <Text>
          Bildförhållande bör vara 3:2 där minsta storlek är 1000px. D.v.s om bredden är 1000px bör höjden vara minst 1500px och vice versa.
        </Text>
      </LegacyCard.Section>
    </LegacyCard>
  );

}

export { MediaUpload }