import { useState, useEffect } from 'react';
import { Pagination, Page, Avatar, ResourceItem, Text, ResourceList, Filters, EmptyState, Layout, LegacyCard, HorizontalStack } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

import { Tag } from '../components/Tag';

import { restApi } from '../providers/restApi';

const AdminList = () => {

  const history = useHistory();

  const primaryAction = {
    content : 'Skapa användare',
    onAction : e => { history.push(`/admin/create`) }
  }

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageSize] = useState(10);
  const [pageFrom, setPageFrom] = useState(0);
  const [numHits, setNumHits] = useState(0);

  const [queryValue, setQueryValue] = useState('');
  const [filterByQueryValue, setFilterByQueryValue] = useState('');

  const appliedFilters = [];
  const filters = [];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let filter = [];
      if(filterByQueryValue)
        filter.push({ 
          wildcard: {
            "userName.keyword": {
              value: `*${filterByQueryValue}*`,
              case_insensitive : true
            }
          }
        })
      let { NumHits, Hits } = await restApi.post('/admin/query', { size : pageSize, from : pageFrom, filter }); 
      setUsers(Hits);
      setNumHits(NumHits)
      setIsLoading(false)
    })();
  }, [pageSize, pageFrom, filterByQueryValue]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterByQueryValue(queryValue)
    }, 700)
    return(() => {
      clearTimeout(timeout);
    })
  }, [queryValue])

  const createUser = () => {
    history.push('/admin/create')
  }

  const filterControl = (
    <Filters
      disabled={!users.length}
      queryValue={queryValue}
      onQueryChange={setQueryValue}
      onQueryClear={e => setQueryValue('')}
      filters={filters}
      appliedFilters={appliedFilters}
    />
  );

  const emptyStateMarkup = (appliedFilters.length === 0 && queryValue === '' && users.length === 0) ? (
    <EmptyState
      heading={'Skapa Användare'}
      action={{content: 'Skapa Användare', onAction : createUser }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
    </EmptyState>
  ) : undefined;

  function renderUser(item) {
    const { id, userName, disabled } = item;
    const subTitle = '';
    const avatar = <Avatar customer size="medium"  />;
    const url = `/#/admin/${id}`;
    const tags = [];
    return (
      <ResourceItem
        id={id}
        url={url}
        media={avatar}
        accessibilityLabel={`Visa detaljer för ${userName}`}
        verticalAlignment="center"        
      >
        <div style={{ flex : 1, display : 'flex', justifyContent : 'flex-start', alignItems : 'center', flexDirection : 'row'}}>
          <div style={{ display : 'flex', flexDirection : 'column'}}>
            {disabled && (<Text color="subdued">Inaktiverad<br /></Text>)}
            <Text variant='heading3' fontWeight="strong">{userName}</Text>
            <Text>{subTitle && (`- ${subTitle}`)}</Text>
          </div>
          <div style={{ marginLeft : 20 }}>
            {tags && (
              <HorizontalStack>
                {tags.map((tag) => {
                  return(
                    <Tag key={tag} type='tag' value={tag} />
                  )
                })}
              </HorizontalStack>
            )}
          </div>
        </div>
      </ResourceItem>
    );
  }

return(
  <Page primaryAction={primaryAction}>
    <Layout>
      <Layout.Section>
        <LegacyCard>
          <LegacyCard.Section>
            <ResourceList
              emptyState={emptyStateMarkup}
              items={users}
              loading={isLoading}
              renderItem={renderUser}
              filterControl={filterControl}
              resourceName={{singular: 'användare', plural: 'användare'}}
            />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <Pagination
              hasPrevious={pageFrom > 0}
              label={`Visar ${pageFrom + 1} till ${Math.min(numHits, pageFrom + pageSize)} av ${numHits}`}
              onPrevious={() => {
                setPageFrom(pageFrom - pageSize)
              }}
              hasNext={(pageFrom+pageSize) < numHits}
              onNext={() => {
                setPageFrom(pageFrom + pageSize)
              }}
            />
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  </Page>
);
}

export { AdminList }