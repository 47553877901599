import { useContext } from 'react';

import { Navigation as ShopifyNavigation } from '@shopify/polaris';
import './Navigation.css';

import { useLocation } from 'react-router-dom';
import { HomeMajor, CalendarMajor, StoreMajor, ActivitiesMajor, EnvelopeMajor, LinkMinor, HintMajor, HashtagMajor, CustomersMajor } from '@shopify/polaris-icons';

import { AuthContext } from './providers/AuthContext';

const Navigation = () => {

  const location = useLocation();
  const authContext = useContext(AuthContext);

  const { level } = authContext.state.user ? authContext.state.user : { level : 'none' };
  const filterByLevel = (item) => {
    if(item.requiredLevel) 
      return(item.requiredLevel.indexOf(level) > -1);
    return(true);
  }

  return(
    <ShopifyNavigation location="/">
      <ShopifyNavigation.Section
        items={[
          {
            label: 'Dashboard',
            url : "/#",
            selected : location.pathname === '/',
            icon: HomeMajor,
            requiredLevel : ['superuser', 'poweruser', 'admin']
          },
          {
            label: 'Evenemang',
            url : "/#/events?status=ongoing&visible=visible-true",
            selected : location.pathname.indexOf('/events') === 0,
            icon: CalendarMajor,
            requiredLevel : ['superuser', 'poweruser', 'admin']
          },
          {
            label: 'Tips',
            url : "/#/tips",
            selected : location.pathname.indexOf('/tips') === 0,
            icon: HintMajor,
            requiredLevel : ['superuser', 'poweruser']
          }
        ].filter(filterByLevel)}
      />
      {['superuser', 'poweruser', 'admin'].indexOf(level) !== -1 && (
        <ShopifyNavigation.Section
          separator
          title="Data"
          items={[
            {
              label: 'Verksamheter',
              url : "/#/organisations",
              selected : location.pathname.indexOf('/organisations') === 0,
              icon: StoreMajor,
              requiredLevel : ['superuser', 'poweruser', 'admin']
            },
            {
              label: 'Anläggningar',
              url : "/#/facilities",
              selected : location.pathname.indexOf('/facilities') === 0,
              icon: ActivitiesMajor,
              requiredLevel : ['superuser', 'poweruser']
            },
            {
              label: 'Länkar',
              url : "/#/links",
              selected : location.pathname.indexOf('/links') === 0,
              icon: LinkMinor,
              requiredLevel : ['superuser', 'poweruser']
            },
            {
              label: 'Taggar',
              url : "/#/tags",
              selected : location.pathname.indexOf('/tags') === 0,
              icon: HashtagMajor,
              requiredLevel : ['superuser', 'poweruser']
            },
            {
              label: 'Pushnotiser',
              url : "/#/push",
              selected : location.pathname.indexOf('/push') === 0,
              icon: EnvelopeMajor,
              requiredLevel : ['superuser', 'poweruser']
            }
          ].filter(filterByLevel)}
        />
      )}
      {['superuser', 'poweruser'].indexOf(level) !== -1 && (
        <ShopifyNavigation.Section
          separator
          title="System"
          items={[
            {
              label: 'Användare',
              url : "/#/admin",
              selected : location.pathname.indexOf('/admin') === 0,
              icon: CustomersMajor,
              requiredLevel : ['superuser', 'poweruser']
            }          
          ].filter(filterByLevel)}
        />
      )}
    </ShopifyNavigation>
  );
}

export { Navigation }

