import { useEffect, useState, useCallback, useContext } from 'react';

import {  Card, Frame, Modal, Page, Box, Text } from '@shopify/polaris';

import 'react-quill/dist/quill.snow.css'; // ES6

import './App.css';
import { Navigation } from './Navigation';

import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import { Login } from './screens/Login';
import { DataList } from './screens/DataList';

import { AuthContext } from './providers/AuthContext';

import { CreateDataObject } from './screens/CreateDataObject';
import { Home } from './screens/Home';
import { FacebookPage } from './screens/FacebookPage';
import { PushHistory } from './screens/PushHistory';
import { Privacy } from './screens/Privacy';
import { PushList } from './lists/Push';
import { DataObjectList } from './lists/DataObject';
import { AdminList } from './lists/Admins';
import { TopBar } from './components/TopBar';

import { DefaultPushPageAction } from './screens/CreateDataObject';
import { DefaultAdminPageAction } from './screens/CreateDataObject';
import { restApi } from './providers/restApi';

import { FacebookEvent } from './screens/FacebookEvent';

function AppFrame() {

  const { state : authState } = useContext(AuthContext);
  const history = useHistory();

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [showOrganisationUpdateModal, setShowOrganisationUpdateModal] = useState(false);
  const [organisationsNeedingUpdate, setOrganisationsNeedingUpdate] = useState([]);

  const { user } = authState;
  const level = user?.level || 'none';
  
  useEffect(() => {
    (async () => {
      const organisations = user?.organisations || [];
      if(organisations) {
        let organisationsNeedingUpdate_ = [];
        for(const organisation of organisations) {
          let org = await restApi.get(`/organisations/${organisation.id}`)
          if(!org.description || !org.name || !org.title) {
            organisationsNeedingUpdate_.push(organisation);
          }
        }
        if(organisationsNeedingUpdate_.length > 0) {
          setShowOrganisationUpdateModal(true);
          setOrganisationsNeedingUpdate(organisationsNeedingUpdate_);
        }
      }
    })();
  }, [user.organisations]);

  const location = useLocation();

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive )
  }, []);  

  const isPowerUser = ['superuser', 'poweruser'].indexOf(level) !== -1;

  const tipHeader = (
    <Card>
      <Text>
        Tipsa oss gärna om kommande evenemang. Efter tipsning kommer vi att kontrollera uppgifterna och därefter publicerar evenemanget.
      </Text>
    </Card>
  )

  const showLoginModal = authState.isAuthenticated === false && ['/tipsa', '/tack', '/privacy-policy'].indexOf(location.pathname) === -1;

  let pageMarkup = (<></>);
  let navigationMarkup = (<></>);
  let topBarMarkup = (<></>);

  if(location.pathname === '/privacy-policy') {
    pageMarkup = (<Privacy />);
  } else if(location.pathname === '/tipsa') {
    pageMarkup = (
      <CreateDataObject
        header={tipHeader}
        dataType='tips'
        singularName='evenemang' 
      />
    )
  } else if(location.pathname === '/tack') {
    pageMarkup = (
      <Frame>
        <Page>
          <Card>
            <Box>
              <h3>
                Tack för ditt tips
              </h3>
              <p>
                Vi kommer nu att granska ditt tips innan vi publicerar det.
              </p>
              <p>
                <a href="#/tipsa">Skicka in ett till tips</a>
              </p>
            </Box> 
          </Card>
        </Page>
      </Frame>
    );
  } else if(authState.isAuthenticated) {
    navigationMarkup = (<Navigation />);
    topBarMarkup = (<TopBar toggleMobileNavigationActive={toggleMobileNavigationActive} />);
    pageMarkup = (
      <>
        <Modal
          open={showOrganisationUpdateModal}
          onClose={() => setShowOrganisationUpdateModal(false)}
          title="Uppdatera organisation"
          primaryAction={{
            content: 'Uppdatera nu',
            onAction: () => {
              history.push('/organisations')
              setShowOrganisationUpdateModal(false)
            }
          }}
        >
          <Modal.Section>
            <Box>
              <p>
                Du har en eller flera organisationer som saknar namn, beskrivning eller rubrik. Var god uppdatera dessa.
              </p>
              <p>
                Organisationer som måste uppdateras:
              </p>
              <ul>
                {organisationsNeedingUpdate.map((org) => (
                  <li key={org.id}>{org.name}</li>
                ))}
              </ul>
            </Box>
          </Modal.Section>
        </Modal>
        <Switch>
          {/* event */}
          <Route path='/events/create'>
            <CreateDataObject dataType='events' singularName='evenemang' />
          </Route>
          <Route path='/events/:id'>
            <CreateDataObject dataType='events' singularName='evenemang' />
          </Route>
          <Route path='/events'>
            <DataObjectList dataType="events" />
          </Route>
          <Route path='/tips/create'>
            <CreateDataObject dataType='tips' singularName='tips' />
          </Route>
          <Route path='/tips/:id'>
            <CreateDataObject dataType='tips' singularName='tips' />
          </Route>
          <Route path='/tips'>
            <DataList dataType='tips' singularName='tips' pluralName='tips' />
          </Route>
          <Route path='/organisations/create'>
            <CreateDataObject dataType='organisations' singularName='organisation' />
          </Route>
          <Route path='/organisations/:id'>
            <CreateDataObject dataType='organisations' singularName='organisation' />
          </Route>
          <Route path='/organisations'>
            <DataObjectList dataType='organisations' levels={['superuser', 'poweruser']}/>
          </Route>
          <Route path='/facilities/create'>
            <CreateDataObject dataType='facilities' singularName='anläggning' />
          </Route>
          <Route path='/facilities/:id'>
            <CreateDataObject dataType='facilities' singularName='anläggning' />
          </Route>
          <Route path='/facilities'>
            <DataObjectList dataType='facilities' pushDataType="facility" />
          </Route>
          <Route path='/push/create'>
            <CreateDataObject dataType='push' singularName='push' pageAction={DefaultPushPageAction} />
          </Route>
          <Route path='/push/:id/history'>
            <PushHistory />
          </Route>
          <Route path='/push/:id'>
            <CreateDataObject dataType='push' singularName='push' pageAction={DefaultPushPageAction} />
          </Route>
          <Route path='/push'>
            <PushList />
          </Route>
          <Route path='/links/create'>
            <CreateDataObject dataType='links' singularName='länk' />
          </Route>
          <Route path='/links/:id'>
            <CreateDataObject dataType='links' singularName='länk' />
          </Route>
          <Route path='/links'>
            <DataList dataType='links' />
          </Route>
          <Route path='/tags/create'>
            <CreateDataObject dataType='tags' singularName='tagg' />
          </Route>
          <Route path='/tags/:id'>
            <CreateDataObject dataType='tags' singularName='tagg' />
          </Route>
          <Route path='/tags'>
            <DataList dataType='tags' />
          </Route>
          <Route path='/admin/create'>
            {isPowerUser && (
              <CreateDataObject dataType='admin' singularName='användare' pluralName='användare' />
            )}
          </Route>
          <Route path='/admin/:id'>
            {isPowerUser && (
              <CreateDataObject dataType='admin' singularName='användare' pluralName='användare' pageAction={DefaultAdminPageAction} />
            )}
          </Route>
          <Route path='/admin'>
            {isPowerUser && (
              <AdminList />
            )}
          </Route>
          <Route path="/facebook/:pageId/:eventId">
              <FacebookEvent />
          </Route>
          <Route path="/facebook/:pageId/">
            <FacebookPage />
          </Route>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </>
    );
  }

  return(
    <Frame
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      topBar={topBarMarkup}
    >
      <Login isVisible={showLoginModal} />
      {pageMarkup}
    </Frame>
  );

}

export { AppFrame };