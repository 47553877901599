
import { Tag as ShopifyTag } from '@shopify/polaris'

const Tag = ({ value, type }) => {
  const label = typeof(value) === 'object' ? value.name : value;
  return(
    <ShopifyTag key={value}>{label}</ShopifyTag>
  )
}

export { Tag }